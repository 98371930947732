.get-started-now-btn {
  background-color: #0b30a9;
  border: none;
  outline: none;
  color: #ffffff;
  height: 60px;
  border-radius: 50px;
  width: 260px;
  font-size: 20px;
}

.card-shadow:hover {
  background-color: #ffffff;
  box-shadow: 1px 1px 5px 0px #5980ff;
}

p.small {
    font-size: 14px;
}

h5.small {
    font-size: 24px;
}

th {
    font-size: 24px;
}

td {
    font-size: 20px;
}

.paymentCards {
    padding-left: 22px;
}

.withdrwaRow .widthdrawl_btn,
.paymentCards .payment_btn {
    background-color: #0B30A9;
    border: none;
    outline: none;
    color: #ffffff;
    border-radius: 50px;
    font-size: 18px;
    padding: 10px 30px;
}

.withdrwal_col {
    text-align: right;
}

.withdrwaRow .paymentMethod {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.paymentCards .balanceCard {
    max-height: 280px;
    overflow-y: scroll;
}

.paymentCards .balanceCard::-webkit-scrollbar {
    width: 5px;
    height: 0px;
}

.paymentCards .balanceCard::-webkit-scrollbar-thumb {
    background: #0000004f;
    border-radius: 20px;
}

.withdrwaRow .balanceCard h5 {
    margin-left: 0px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
}

.withdrwaRow .balanceCard p {
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}

.withdrwaRow .balanceCard .usd {
    font-size: 18px;
}

.paymentCards .balanceCard,
.withdrwaRow .balanceCard {
    box-shadow: 0 0px 15px -3px rgb(0 0 0 / 7%), 0 0px 6px -2px rgb(0 0 0 / 5%);
    border-radius: 20px;
    padding: 36px 28px;
    background: #fff;
    min-height: 290px;
    margin: 10px 0px;
}

.custm_masterCard .deleteIcon {
    background: red;
    padding: 4px;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    text-align: center;
    margin-left: auto;
}

.custm_masterCard .custmImg {
    display: flex;
    justify-content: space-evenly;
}

.custmImg>div:last-child>p,
.custmImg+div>p {
    margin: 0;
}

.balanceCard .borderRow {
    border-top: 1px solid #EBEBEB;
    padding-top: 12px;
}

.withdrwaRow .withdrw,
.paymentCards .payment {
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin: 30px 0;
}

.custm_masterCard {
    padding: 14px 0;
}

.custm_masterCard:last-child {
    border: none;
}

.custm_masterCard>div {
    display: flex;
    align-items: center;
}

.custm_masterCard>div>div {
    display: flex;
    flex-direction: column;
}

.custm_masterCard .custmImg {
    flex-direction: row;
    align-items: center;
}

.mywalletPara {
    margin-top: 10px;
}


/* Table css starts */

.invoice_activity h5 {
    margin: 20px 0;
    font-family: "Poppins", sans-serif;
}

.custmTable {
    height: unset;
}

.custmTable thead tr th,
.AppointmentTables tbody tr td {
    padding: 17px;
}

.custmTable th,
td {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
}


/* Table css ends */


/* Modal Css Starts here */

.mainRow_modal {
    margin-top: 40px;
}

.mainRow_modal .mainRow_border {
    border-right: 1px dotted #7d7d7d;
    ;
}

.modal-lg,
.modal-xl {
    max-width: 1100px;
}

.custmModal_Body h5 {
    text-align: center;
    margin-bottom: 30px;
    color: #000;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
}

.main_cont .balacnceAmount p {
    color: #000000;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    font-size: 17px;
}

.custmRadioBtn .mainWithdrawl_Label {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
}

.paymentMethod {
    margin-top: 20px;
}

.custmCredit_Withdraw p.big,
.withdrawl_inner p.big,
.payment_statusCont p.big,
.paymentMethod p.big {
    color: #000;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
}

.modal_nextBtn {
    text-align: center;
    margin: 20px 0;
}

.modal_nextBtn>button {
    background-color: #0B30A9;
    border: none;
    outline: none;
    color: #ffffff;
    border-radius: 50px;
    font-size: 18px;
    padding: 11px 71px;
}

.prevBtn>button {
    background-color: #0B30A9;
    border: none;
    outline: none;
    color: #ffffff;
    border-radius: 50px;
    font-size: 16px;
    width: 40px;
    height: 40px;
}

.mainRow_modal .prevBtn {
    /* margin-top: -32px; */
}

.stepperModal {
    position: relative;
}

.step0 {
    position: absolute;
    left: 30%;
}

.step1 {
    position: absolute;
    right: 30%;
}


/* profile */

.withdrawModal .prevBtn {
    margin-top: 10px;
}

.ProfileRow .profile_img .profile_imgRadius {
    width: 150px;
    height: 150px;
    margin: 0 auto;
    border-radius: 38px;
    margin-bottom: 30px;
}

.ProfileRow .profile_img {
    text-align: center;
}

.ProfileRow .profile_img img {
    width: 150px;
    height: 150px;
}

.ProfileRow .profile_img p.big {
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #000;
}

.ProfileRow .withdrwaBtnn>button {
    background-color: #0B30A9;
    border: none;
    outline: none;
    color: #ffffff;
    border-radius: 50px;
    font-size: 18px;
    padding: 10px 30px;
}


/* Modal Css Starts ends */


/* AppointmentDescriptionModal and are you sure modal css strars */

.appointmentDespModalBody,
.areYouSureModal_Body {
    text-align: center;
}

.appointmentDesrModal_inner h4,
.areYouSureModal_head h4 {
    font-size: 30px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #000000;
}

.appointmentDesrModal_inner .appointmentDesrModal_imgOuter {
    width: 150px;
    height: 150px;
    border-radius: 30px;
    box-shadow: 0 0px 15px -3px rgb(0 0 0 / 7%), 0 0px 6px -2px rgb(0 0 0 / 5%);
    margin: 30px auto;
    overflow: hidden;
}

.appointmentDesrModal_inner .appointmentDesrModal_imgOuter img {
    width: 100%;
    height: 100%
}

.paraTimingDes {
    font-size: 15px;
}

.appointmentDesrModal_inner .appointmentDespModal_text p.big,
.areYouSureModal_text p {
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #000000;
}

.small-modal-x .modal-content {
    width: 50%;
    margin: 0 auto;
}

.apppointmentDest_closeBtn>button,
.areYouSureBtn>button {
    padding: 12px 73px;
    background-color: #ef464b;
    color: white;
    border-radius: 30px;
    width: unset;
}

.areYouSureBtn {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.areYouSureModal .modal-content {
    width: 50%;
    margin: 0 auto;
}

.msg.modal-body {
    text-align: left;
}

.modal-msg > .modal-lg{
    max-width: 700px;
}
.font40 {
    font-size: 40px;
}

.color_white {
    color: white;
}

.font24 {
    font-size: 24px !important;
}

.main_content {
    /* padding: 50px 60px 50px 0px; */
}

.checkout_heading {
    margin: 40px auto;
    padding-left: 30px;
    color: black;
}

th {
    text-align: center;
}

th:first-child {
    /* color: #0b30a9; */
    text-align: left;
}

.cart_icon {
    color: #0b30a9;
    font-size: 35px;
}


/* 
.checkout_table {
    border: 1px solid red;
} */

.checkout_table th {
    font-size: 20px;
}

.checkout_table .item_content p {
    font-size: 15px;
}

.checkout_table .order_item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.item_img {
    width: 100px;
    height: 100px;
    padding: 10px;
    background: #f3f3f3;
    border-radius: 10px;
}

.item_img img {
    width: 100%;
    height: auto;
}

.item_content p {
    margin: 0;
}

.item_content {
    margin-left: 10px;
}

.order_quantity span.minus_btn {
    background: #dfe8f2;
    border-radius: 50%;
    /* padding: 0px 14px; */
    font-size: 32px;
    color: #a1b0c1;
    margin-right: 10px;
    width: 50px;
    height: 50px;
    text-align: center;
}

.order_quantity span.plus_btn {
    background: #dfe8f2;
    border-radius: 50%;
    padding: 0px 12px;
    font-size: 32px;
    color: #a1b0c1;
    margin-left: 10px;
    width: 50px;
    height: 50px;
    text-align: center;
}

.order_quantity {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.order_quantity .number {
    font-size: 20px;
}

.unit_price h5,
.total_price h5 {
    margin: 0;
    font-size: 25px;
    font-weight: bold;
}

.delete_icon {
    font-size: 30px;
    color: #ef464b;
}

.summary__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.summary_block {
    /* border: 1px solid red; */
    padding: 30px 25px;
    border-radius: 10px;
    background-color: #0b30a90c;
    /* background-color: #DFE8F2; */
    box-shadow: 0px 3px 6px #00000018;
}

.summary_block h4 {
    color: #0b30a9;
    font-size: 31px;
}

.summary_block p {
    color: #3f4c58;
    font-size: 24px;
}

.summary_block .summary__content:last-child>p {
    color: black;
    font-weight: bold;
}

.proceed_btn {
    text-align: center;
    /* border: 1px solid blue; */
    width: 100%;
    height: auto;
    margin-top: 60px;
    /* margin-left: 22px; */
}

.proceed_btn a {
    color: white !important;
    background-color: #0b30a9;
    padding: 15px 60px;
    font-size: 20px;
    border-radius: 50px;
    box-shadow: 0px 10px 30px #0b30a933;
}


/* SHIPPING DETAILS SCREEN STYLING */

.shipping_details_block h4 {
    font-size: 40px;
    font-weight: bold;
    color: black;
    margin: 30px 0px 40px 0px;
}

.shipping_details {
    /* border: 1px solid #0B30A9; */
    /* padding: 0px 50px 30px 50px; */
    border-radius: 20px;
    box-shadow: 0px 10px 30px #0000001e;
}

.shipping_details h5 {
    background-color: #0b30a9;
    padding: 15px 0px 15px 30px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.shipping_details .form_fields {
    padding: 20px 30px 80px 30px;
}

.shipping_details .form_fields input,
select {
    /* height: 50px; */
    margin: 10px auto;
    /* border-radius: 50px; */
    padding: 10px 10px;
}

.shipping_details select {
    /* border-radius: 50px; */
    outline: none;
    border: none;
}

.order_details {
    margin-left: 22px;
    margin-top: 40px;
}

.shipping_details input::placeholder,
.shipping_details select::placeholder {
    color: #6a6a6a !important;
    font-size: 16px;
}

.order-item-x-img {
    height: 100% !important;
    object-fit: contain !important;
}

.order-item-text-p {
    display: flex;
    flex-wrap: wrap;
}

.order-item-text-p p {
    width: 50%;
}

.orders-s-head-sp {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.orders-s-null-data {
    margin-top: 20%;
}

.f-m-h {
    font-size: 20px;
    font-weight: bold;
    color: black;
}
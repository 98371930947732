.cards-row-x {
  display: flex;
  justify-content: space-evenly;
}

.card-x {
  display: grid;
  place-items: center;
}

.card-container-x {
  background-color: #fff;
  width: 80%;
  padding: 20px;
  display: grid;
  place-items: center;
  border-radius: 10px;
  cursor: pointer;
  height: 220px;
  border: 2px solid #fff;
  border-top: 8px solid #fff;
}

.card-container-x-selected {
  border: 2px solid #ef464b;
  border-top: 8px solid #ef464b;
}

.card-img-container-x {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.card-img-container-x img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.card-x h5 {
  color: #7c7c7c;
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
}

.t-t-c {
  text-transform: capitalize;
}

/* body-loader */

.body-loader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(50, 82, 187, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0px 10px 30px 0 rgba(43, 45, 66, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  z-index: 9999;
}

/* p-d-null-data */

.p-d-null-data {
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preferences-x-c {
  display: flex;
  flex-wrap: wrap;
}

.f-e {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.btn-bid-x {
  border-radius: 50px;
  color: #ffffff;
  background-color: #ef464b;
  border: 1px solid #ef464b;
  width: 250px !important;
  height: 60px !important;
  font-weight: 500 !important;
  font-size: 20px;
  text-align: center;
  padding-top: 4px;
  text-align: center;
}

.doctor-near-null-data-c {
  position: absolute;
  top: 30%;
  left: 50%;
}

/* /input-group */

.flex-center .input-group input {
  padding: 22px 20px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* /text-icon-x */

.text-icon-x {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-icon-container {
  background-color: #eee;
  padding: 10px 20px;
  border-radius: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.cart-icon-container .cartIcon {
  width: 30px;
  height: 30px;
  margin-right: 8px;
}

.cart-icon-container span {
  font-size: 20px;
  font-weight: bold;
}

/* pharmacyCardDeleteBtn */

.pharmacyCardDeleteBtn {
  background-color: #ef464b;
  color: white;
  width: 191px;
  height: 46px;
  background: #ef464b 0% 0% no-repeat padding-box;
  border-radius: 50px;
  border: none;
}

.null-data-checkout-x {
  display: flex;
  height: 400px;
  width: 100%;
  display: grid;
}

.proceed_btn_disabled a {
  background-color: gray !important;
  cursor: no-drop;
}

.shipping-deyail-prescription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.px-290 {
  width: 290px !important;
}

@media screen and (max-width: 1440px) {
  .card-container-x {
    width: 100%;
  }
  .loginFormSection {
    display: flex;
    flex-direction: column;
    row-gap: 41px;
    padding-block: 100px;
    padding-inline: 40px;
    position: relative;
  }
  .loginImg {
    padding: 60px;
  }
  .loginLogo {
    margin: 0;
  }
  .loginForm {
    padding: 0;
  }
  .loginImg > img {
    max-width: 100%;
    height: auto;
  }
  .LoginBgImg {
    left: 70%;
  }
  .opt-outer-div {
    height: 50vh;
  }
  .otp-container {
    width: 42%;
  }
  .textBlack {
    font-size: 17px;
  }
  .AppointmentTables thead tr th,
  .AppointmentTables tbody tr td {
    padding: 10px;
  }
  .AppointmentTables thead th {
    font-size: 18px !important;
  }
  .appointmentsTables td {
    font-size: 17px;
  }
  .flex-center .input-group input {
    padding: 22px 5px;
  }
  .b-round {
    padding-left: 15px;
  }
  .datePicker {
    padding-left: 6px;
    font-size: 12px;
  }
  .appointmentsTables {
    height: 484px;
    overflow-y: scroll;
  }
  .appointmentsTables::-webkit-scrollbar {
    width: 5px;
    height: 0px;
  }
  .appointmentsTables::-webkit-scrollbar-thumb {
    background: #0000004f;
    border-radius: 20px;
  }
  .chat-section .chats-right-box {
    height: 526px !important;
  }
  .chat-section .chats-left-box {
    height: 565px !important;
  }
  .chat-section .chats-left-box-messages {
    height: 400px !important;
  }
  h6 {
    font-size: 14px;
  }
  .chat-section .chats-right-box .chats-container {
    height: 389px !important;
  }
  .padding-fluid-container {
    padding: 0 !important;
  }
  /* .col-lg-3 {
        padding: 0;
    } */
  .chat-section .chats-left-box .chat-list {
    padding-right: 12px;
    padding: 0 12px;
  }
}

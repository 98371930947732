.chat-section {
    /* margin-top: 5%;
    margin-bottom: 25%; */
}

.chat-section .chats-left-box {
    border-radius: 15px;
    /* max-height: 80vh;
    min-height: 80vh; */
    height: 850px;
    box-shadow: 0px 0px 12px #0000001a;
    /* width: 290px; */
    padding: 6px;
}

.chat-section .chats-left-box .chat-header {
    height: 9vh;
    display: flex;
    align-items: center;
}

.chat-section .chats-left-box .chat-header h5 {
    padding-left: 5%;
    font-weight: bold;
}

.chat-section .chats-right-box {
    border-radius: 15px;
    /* height: 80vh; */
    height: 850px;
    box-shadow: 0px 0px 12px #0000001a;
}

.chat-section .chats-right-box .chat-header {
    height: 10vh;
    box-shadow: 0px 2px 50px #8686861a;
    display: flex;
    align-items: center;
    padding: 0px 3%;
}

.chat-section .chats-right-box .chat-header h6 {
    margin-left: 1%;
    margin-bottom: 0px;
    width: 100%;
}

.chat-section .chats-right-box .chat-header span {
    color: #888888;
    font-size: 14px;
}

.chat-section .chats-right-box .chats-container {
    /* max-height: 60vh; */
    /* min-height: 60vh; */
    height: 665px;
    overflow-y: scroll;
    padding: 2% 0px;
}

.chat-section .chats-right-box .chats-container .container {
    max-width: 98%;
}


/* Empty Chhat List */

.chat-section .chats-left-box-messages .empty-chat-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}

.chat-section .chats-left-box-messages .empty-chat-list span {
    color: #505050;
}


/* Scrollbar */

.chat-section .chats-right-box .chats-container::-webkit-scrollbar,
.chat-section .chats-left-box-messages::-webkit-scrollbar {
    width: 6px;
}

.chat-section .chats-right-box .chats-container::-webkit-scrollbar-track {
    background-color: #ebebeb;
    border-radius: 10px 0px 0px 10px;
}

.chat-section .chats-right-box .chats-container::-webkit-scrollbar-thumb {
    background-color: #d1d1d1;
    border-radius: 10px 0px 0px 10px;
}


/* Left scrollbar */

.chat-section .chats-left-box-messages {
    overflow-y: scroll;
    /* height: 69vh; */
    height: 740px;
}

.chat-section .chats-left-box-messages::-webkit-scrollbar-track {
    background-color: #ebebeb;
    border-radius: 10px;
}

.chat-section .chats-left-box-messages::-webkit-scrollbar-thumb {
    background-color: #d1d1d1;
    border-radius: 10px;
}

.chat-section .chats-right-box .chats-container .left-message {
    display: flex;
}

.chat-section .chats-right-box .chats-container .left-message .message {
    min-width: min-content;
    max-width: max-content;
    box-shadow: 0px 20px 50px #0000001a;
    height: max-content;
    padding: 10px 20px;
    border-radius: 15px 15px 15px 0px;
    background-color: #EF464B;
    margin-left: 10px;
    margin-top: 20px;
    margin-bottom: 0px;
    color: #ffffff;
    position: relative;
}

.chat-section .chats-right-box .chats-container .left-message .time {
    display: block;
    margin-left: 10px;
    color: #707070;
    margin-top: 5px;
}


/*  Arrow */

.chat-section .chats-right-box .chats-container .left-message .arrow::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    float: left;
    bottom: -6px;
    left: 0px;
    border: 6px solid;
    border-color: #EF464B transparent transparent #EF464B;
}

.chat-section .chats-right-box .chats-container .right-message .message .arrow::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0px;
    bottom: -6px;
    right: 0px;
    border: 6px solid;
    border-color: #EFEFEF #EFEFEF transparent transparent;
}

.chat-section .chats-right-box .chats-container .right-message {
    display: flex;
    flex-direction: column;
    float: right;
    margin-bottom: 2%;
}

.chat-section .chats-right-box .chats-container .right-message .message {
    color: black;
    background-color: #EFEFEF;
    min-width: min-content;
    max-width: max-content;
    box-shadow: 0px 20px 50px #0000001a;
    height: 100%;
    padding: 10px 20px;
    border-radius: 15px 15px 0px 15px;
    margin-bottom: 0px;
    position: relative;
}

.chat-section .chats-right-box .chats-container .right-message .time {
    color: #707070;
    float: right;
    font-size: 16px;
    margin-top: 5px;
}


/*  Chat List */

.chat-section .chats-left-box .chat-list {
    height: max-content;
    cursor: pointer;
    padding-right: 12px
}

.chat-section .chats-left-box .chat-list:hover {
    background-color: rgba(25, 154, 180, 0.3);
}

.chat-section .chats-left-box .chat-list .container {
    border-bottom: 1px solid rgb(214, 214, 214);
    max-width: 90%;
    padding: 5% 0px;
}

.chat-section .chats-left-box .chat-list .username {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.chat-section .chats-left-box .chat-list .username h6 {
    margin-bottom: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chat-section .chats-left-box .chat-list .username span {
    margin-top: 5px;
    font-size: 13px;
    color: #888888;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.chat-section .chats-left-box .chat-list .time-box {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
}

.chat-section .chats-left-box .chat-list .time-box span {
    font-size: 14px;
    color: #888888;
}

.chat-section .chats-left-box .chat-list .time-box .remaining-msgs {
    margin-top: 5px;
    background-color: #92d828;
    color: #ffffff;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    text-align: center;
}


/* Chat Timeline */

.chat-section .chats-right-box .chat-timeline {
    margin-bottom: 3%;
}

.chat-section .chats-right-box .chat-timeline .left-border {
    border-bottom: 1px solid #505050;
    height: 100%;
}

.chat-section .chats-right-box .chat-timeline .right-border {
    border-bottom: 1px solid #505050;
    height: 100%;
}

.chat-section .chats-right-box .chat-timeline .time {
    color: #505050;
}


/*  Chat Footer */

.chat-section .chats-right-box .chat-footer {
    padding: 1% 5%;
    height: auto;
    box-shadow: 0px -10px 50px #0000001a;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background: white;
}

.chat-footer>div>div {
    display: flex;
    align-items: center;
}

.chat-section .chats-right-box .chat-footer .message-input {
    width: 100%;
    outline: none;
    padding: 2% 20px;
    /* height: 6vh; */
    border-radius: 50px;
    border: 1px solid rgb(214, 214, 214);
}

.chat-section .chats-right-box .chat-footer .message-input:focus {
    border: 1px solid #173BAF;
}

.chat-section .chats-right-box .chat-footer .message-input::placeholder {
    color: black !important;
}

.chat-section .chats-right-box .chat-footer .send-btn {
    /* height: 70px;
    width: 70px; */
    border-radius: 50%;
    border: none;
    outline: none;
    padding: 15px;
    background-color: #1034AB;
}

.chat-section .chats-right-box .chat-footer .send-btn .icon {
    width: 30px;
    height: 30px;
    color: #ffffff;
}

.chat-section .chats-right-box .chat-footer .send-btn:hover {
    background-color: #173BAF;
    transition: all 0.2s linear;
}


/* Empty Box */

.chat-section .chats-right-box .center-empty-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
}

.chat-section .chats-right-box .center-empty-icon h3 {
    color: #183BAF;
    text-align: center;
}

.chat-section .chats-right-box .center-empty-icon img {
    width: 200px;
    height: 200px;
}


/* 
  @media screen and (min-width: 2500px) {
    .chat-section .chats-left-box {
      max-height: 70vh;
      min-height: 70vh;
    }
    .chat-section .chats-left-box .chat-header {
      height: 5vh;
    }
    .chat-section .chats-left-box-messages {
      height: 65vh;
    }
    .chat-section .chats-right-box .chat-header {
      height: 7vh;
    }
    .chat-section .chats-right-box {
      height: 70vh;
      border: 1px solid red !important;
    }
    .chat-section .chats-right-box .chats-container {
      max-height: 56vh;
      min-height: 56vh;
    }
    .chat-section .chats-right-box .chat-footer {
      padding: 1vh 5%;
      height: 7vh;
    }
    .chat-section .chats-right-box .chat-footer .message-input {
      height: 4.5vh;
    }
  } */

@media screen and (max-width:1439px) {
    .chat-section .chats-right-box {
        height: 412px;
    }
    .chat-section .chats-right-box .chats-container {
        height: 321px;
    }
    .chat-section .chats-left-box {
        height: 462px;
    }
    .chat-section .chats-left-box-messages {
        height: 391px;
    }
}
.doctor-mobile-screen {
  display: none;
}

.profille-text {
  text-align: center;
}

.wallet-icon {
  /* font-size: 40px;
    margin-right: 10px; */
  width: 33px;
  height: 33px;
  margin-right: 20px;
}
.wallet-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.footer-ul-icon{
    margin-left: 10px;
}
.footertxtCent.footertxtCent2 h6 {
    margin-top: 11px;
}
.login-container{
    padding: 0;
}
.padding-fluid-container-xx{
width: 100%;
}

@media (min-width: 1920px) {
  .navbar-padding-x {
    margin-left: -180px;
  }
  .navbar-padding-xx {
    margin-left: -90px;
  }
}

@media (max-width: 1920px) {
  .about_boxsRow {
    bottom: -149px;
  }
  .listItmesss {
    margin-top: 190px;
  }
  .redBgImg {
    height: 917px !important;
  }
  .redBgImg_Right {
    height: 916px;
  }
  .whatsholudIVaccine {
    padding-top: 80px !important;
  }
}

@media (max-width: 1600px) {
  .notification-menu
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
    transform: translate(-35%, 49px) !important;
  }
  .sideNavbar ul li {
    margin: 32px 0;
  }
  .doctor-img-box {
    width: 160px;
  }
  .marginTop {
    margin-top: 100px !important;
  }
  .SelectCityMain button {
    font-size: 16px;
  }
  .MidLabBtn button {
    width: 250px;
    height: 50px;
  }
  .doctordetail-icon {
    margin-right: 10px;
    margin-top: 4px;
  }
  .doctor-detail-sectionss svg {
    float: left;
  }
  .doctor-detail-sectionss span {
    display: flex;
  }
  .doctor-detail-sectionss {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  .summary_block p {
    font-size: 18px;
  }
  .unit_price h5,
  .total_price h5 {
    font-size: 18px;
  }
  .summary_block h4 {
    font-size: 26px;
  }
  .bold_custom {
    font-size: 30px;
  }
  .item_img {
    height: 80px;
  }
  /* ABout Us page starts */
  .Problem_Worth::before {
    transform: scaleX(1.3);
    left: 19px;
  }
  .unique_selling {
    margin: -20px 0 50px 0;
  }
  /* ABout Us page ends */
  .card_img {
    flex-shrink: 0;
  }
  .card_content {
    display: flex;
    flex-wrap: wrap;
  }
  .name_date {
    flex-wrap: wrap;
  }
  .bold_custom {
    width: 100%;
  }
  .msg-para {
    width: 100px !important;
  }
  /* Dashboard page ends */
  .exploreByCategory::before {
    transform: scaleX(1.3) !important;
  }
  .exploreByCategory {
    padding-bottom: 240px !important;
  }
  .city-code-div input {
    width: 133% !important;
  }
  .textBlack {
    font-size: 12px;
  }
  .chat-section .chats-left-box {
    height: 600px !important;
  }
  .chat-section .chats-left-box-messages {
    height: 497px !important;
  }
  .chat-section .chats-right-box .chats-container {
    height: 448px !important;
  }
  .chat-section .chats-right-box {
    height: 599px !important;
  }
  .textBlack {
    font-size: 12px;
  }
  .web-color {
    font-size: 13px;
  }
}

@media (max-width: 1440px) {
  .navbar-nav {
    margin-left: 30px;
  }
  .week-box {
    margin-top: 42px;
  }
  #google_translate_element .skiptranslate.goog-te-gadget {
    margin-top: 12px !important;
  }
  .marginTop {
    margin-top: 130px;
  }
  .storeImg {
    display: flex;
  }
  /* About Page starts */
  .Problem_Worth {
    padding-left: 280px;
  }
  .home_banner_b:before {
    right: 4px;
  }
  .doctorRedBg {
    margin-top: 180px !important;
  }
  .Problem_Worth::before {
    transform: scaleX(1.1);
    left: 95px;
  }
  .abtTable_main::before {
    background-image: unset;
  }
  .abtTable_main::after {
    background-image: unset;
  }
  /* About Page ends */
  .doctor-Dashboard-top {
    margin: 0;
  }
  .doctor-Dashboard-top .container {
    padding: 0;
  }
  .padding-fluid-container {
    padding: 0;
  }
  .sideNavbar ul li {
    margin: 25px 0;
  }
  .Dashboardactive {
    width: 240px;
    height: 50px;
  }
  /* search-preferences page css */
  button.text-left {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  /* search-preferences page css ends*/
  /* .doctorsNearsCard {
                      width: 400px;
                      height: 200px;
                  } */
  .schedule button {
    width: 140px;
  }
  .proceed_btn a {
    padding: 12px 28px;
    font-size: 18px;
  }
  .delete_icon {
    font-size: 20px;
    color: #ef464b;
  }
  .font24 {
    font-size: 18px !important;
  }
  .order_quantity span.minus_btn,
  .order_quantity span.plus_btn {
    font-size: 26px;
    width: 40px;
    height: 40px;
  }
  .item_img {
    height: 60px;
  }
  .home_banner_b {
    max-width: 1247px;
  }
  .solution_bg .row {
    --mdb-gutter-x: 0px;
  }
  .max-width {
    max-width: 90%;
  }
  /* Persnol Information starts */
  .opt-outer-div {
    height: 100vh;
  }
  .persnolInfo_upload {
    padding-left: 50px;
  }
  /* Persnol Information ends */
  /* Chat screen starts */
  .chat-section .chats-left-box .chat-list .username {
    padding-left: 10px;
  }
  .chat-section .chats-left-box .chat-list {
    width: 90%;
    padding-right: unset;
    padding: unset;
  }
  /* Chat screen ends */
  .opt-outer-div {
    height: 100vh;
    margin: 160px 0;
  }
  .textBlack {
    font-size: 12px;
  }
  .apexcharts-canvas {
    /* position: relative;
        -webkit-user-select: none;
        user-select: none; */
    right: 85px !important;
  }
  .schdulors.row {
    /* grid-gap: 30px; */
    gap: 7px;
  }
  .web-color {
    font-size: 13px;
  }
}

@media (max-width: 1400px) {
  #google_translate_element .skiptranslate.goog-te-gadget {
    margin-top: 0px !important;
  }
  .navbar-nav {
    margin-left: 20px;
  }
  /* About us starts */
  .font20 {
    font-size: 18px;
  }
  .textBlack {
    font-size: 15px;
  }
  .Problem_Worth::before {
    transform: scaleX(1);
    left: 11px;
  }
  .doctorRedBg {
    margin-top: 250px !important;
  }
  .about_boxsRow {
    gap: 60px;
  }
  .custmContainer > .row {
    justify-content: center;
  }
  .about_business {
    padding: 0 24px;
  }
  /* About us ends */
  /* #########Home Page CSS Starts############## */
  .homeBgRightangle {
    max-width: 6%;
    right: -2%;
  }
  .helpbg_1 {
    right: 40%;
    max-width: 12%;
  }
  .navbar {
    padding: 10px 0;
  }
  .solution_bg {
    background-size: 92%;
  }
  p.mytute_p {
    max-width: 80%;
  }
  /* #########Home Page CSS Ends############## */
  .doctor-Dashboard-top {
    margin: 0;
  }
  .doctor-Dashboard-top .container {
    padding: 0;
  }
  .home_banner_b {
    max-width: 1212px;
  }
  /* Dashboard starts */
  .sideNavbar .ms-5 > img {
    width: 80% !important;
    margin: 0 auto;
  }
  #chart {
    margin-left: -60px;
  }
  /* Dashboard ends */
  .notification-menu
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
    width: 40%;
    transform: translate(-49%, 53px) !important;
  }
  .city-code-div {
    width: 133% !important;
  }
  .doctorsNearsCard h6 {
    font-size: 16px !important;
    font-weight: bold;
  }
  .pharmacy-card-box {
    /* justify-content: center; */
    column-gap: 90px;
  }
  .pharmacy-card {
    width: 130%;
    padding: 10px;
  }
  .pharmacy-img {
    /* width: 160px;
                      height: 160px; */
  }
  .text-icon-x {
    justify-content: space-between;
  }
  .pharmacyMain h6 {
    font-size: 15px;
  }
  .pharmacyMoney {
    font-size: 16px;
  }
  .pharmacyCardAddBtn {
    width: 165px;
    height: 40px;
  }
  .apexcharts-canvas {
    right: 54px !important;
  }
}

@media (max-width: 1366px) {
  .dashboard h5 {
    font-size: 20px;
  }
  .MedicalHead p {
    font-size: 16px;
  }
  .doctor-detail-imgBox {
    width: 100px;
    height: 100px;
  }
  .MedicalH li {
    font-size: 16px;
  }
  .week-box {
    margin-top: 60px;
  }
  .navbar {
    padding: 10px 0px !important;
  }
  .home_banner_b {
    max-width: 1184px;
  }
  .exploreByCategory::before {
    transform: scaleX(1.1) !important;
  }
  .ms-5 img {
    margin-left: -21px;
  }
  .sideNavbar ul li {
    padding-left: 10px;
  }
  .Dashboardactive {
    width: 225px;
  }
  .schedule button {
    width: 130px;
  }
  .max-width {
    max-width: 100%;
  }
  .doctor-Dashboard-top .container {
    max-width: 100%;
  }
  /* Persnol Information starts */
  .setting_fileupload {
    width: 34%;
  }
  .city-number-div input {
    width: 98% !important;
    margin-left: 10px !important;
  }
  .web-color {
    font-size: 13px;
  }
  .apexcharts-canvas {
    right: 54px !important;
  }
  /* Persnol Information ends */
}

@media (max-width: 1280px) {
  .textBlack {
    font-size: 12px;
  }
  .web-color {
    font-size: 12px;
  }
  .ProfileRightBoxTopImg img {
    width: 100px;
    height: 100px;
  }
  .ProfileRightBoxTop h4 {
    font-size: 16px !important;
  }
  .bold_custom {
    font-size: 22px;
  }
  .doctor_dashboard_newyork_block h6 {
    font-size: 15px !important;
  }
  .font26 {
    font-size: 20px !important;
  }
  .ProfileRightBoxSeeAll h6 {
    font-size: 16px !important;
  }
  .ProfileRightBoxSeeAll p {
    font-size: 14px !important;
  }
  .font20 {
    font-size: 16px !important;
  }
  .MedicalH li {
    font-size: 14px !important;
  }
  .dashboard h5 {
    font-size: 22px !important;
    /* margin-left: 20px; */
    /* width: min-content; */
  }
  .MedicalHead p {
    font-size: 14px !important;
  }
  .exploreByCategory::before {
    transform: scaleX(1.2) !important;
  }
  .doctorRedBg {
    margin-top: 290px !important;
  }
  .doctorRedBg {
    margin-bottom: 130px;
  }
  /* about page starts */
  h1 {
    font-size: 60px;
  }
  h3 {
    font-size: 40px;
  }
  h4 {
    font-size: 35px;
  }
  .font-60 {
    font-size: 40px;
  }
  .abtTable_main {
    overflow-x: scroll;
  }
  .Problem_Worth::before {
    transform: scaleX(0.9);
    left: 60px;
  }
  .Problem_Worth {
    padding-left: 255px;
  }
  .main_tablee {
    position: unset;
  }
  .about_boxsRow {
    bottom: -206px;
  }
  /* about page ends */
  .schedule button {
    width: 120px;
    height: 55px;
  }
  /* Home page starts*/
  h3.text-white.text-left.fw-bold.font-60.people-says-about-us-title-box.whatPeopleHead {
    max-width: 84% !important;
  }
  .home_banner_b {
    max-width: 1110px;
  }
  .homeBgRightangle {
    max-width: 8%;
    right: -2%;
  }
  .sign-up-btn,
  .login-btn {
    font-size: 16px;
    width: 110px !important;
    height: 50px !important;
  }
  .navbar {
    padding: 0 38px;
  }
  .solution_bg {
    background-size: 90%;
    margin-top: 250px;
  }
  .circle {
    width: 60px;
    height: 60px;
    left: 7px;
  }
  .line::before {
    left: 30px;
  }
  /* Home page ends*/
  /* Dashboard starts */
  /* Dashboard ends */
  /* Setting starts */
  .Dashboardactive {
    width: 200px;
  }
  .ms-5 {
    margin-left: 1rem !important;
  }
  /* Setting ends*/
  .appointment_main {
    padding: 0;
  }
  /* Wallet page starts */
  h5.small {
    white-space: nowrap;
  }
  .invoice_activity h5 {
    white-space: nowrap;
  }
  .apexcharts-canvas {
    right: 40px !important;
  }
  /* Wallet page ends */
}

@media (max-width: 1024px) {
  .b-round {
    padding-left: 8px !important;
    font-size: 11px !important;
  }
  .datePicker {
    padding-left: 7px !important;
    font-size: 10px !important;
  }
  .pharmacy-card-box {
    column-gap: 70px;
  }
  .navbar {
    padding: 0px 0px !important;
  }
  #google_translate_element .skiptranslate.goog-te-gadget {
    margin-top: 0px !important;
  }
  .dashboard h5 {
    font-size: 16px !important;
  }
  .ProfileRightBoxSeeAll {
    margin: 10px 0 0 0;
  }
  .dashborad-profile-inner-box .icon-red {
    padding: 4px;
  }
  .dashborad-message-box .msg-text {
    margin-left: 5px;
  }
  .msg-img-box {
    width: 100px;
    height: 70px;
  }
  .msg-text span.\.small {
    font-size: 12px;
  }
  .css-nen11g-MuiStack-root {
    margin: 40px 0;
  }
  .textBlack {
    font-size: 12px;
  }
  h3 {
    font-size: 35px;
  }
  h5 {
    font-size: 26px;
  }
  h6 {
    font-size: 18px;
  }
  p,
  p.big {
    font-size: 16px;
  }
  .textBlack {
    font-size: 11px;
  }
  .web-color {
    font-size: 14px;
  }
  .navbar-nav {
    margin-left: 0px;
  }
  .navbar .navbar-brand img {
    width: 85%;
  }
  .font-60 {
    font-size: 35px;
  }
  .crossImage {
    bottom: 5%;
  }
  .heading {
    height: 40%;
    max-width: 100%;
    margin: 0 auto;
  }
  .pharmacyBgRightangle {
    display: none;
  }
  .home_banner_b:before {
    width: 53%;
    height: 990px;
  }
  .virus-right-first {
    position: unset;
    display: none;
  }
  .virusCut {
    display: none;
  }
  .about_mytute {
    margin-top: 230px;
  }
  .doctorRedBg {
    margin-top: 19px;
  }
  .marginTop {
    margin-top: 30px !important;
  }
  .coronavirus_covid19 {
    width: 100% !important;
  }
  .home-right-first {
    transform: scale(1);
  }
  .card-title.h5 {
    font-size: 22px;
  }
  .p_small {
    font-size: 16px;
  }
  .card {
    padding: 5px;
  }
  /* img.card-img-top {
                      width: 80%;
                      height: 80%;
                  } */
  .max-width {
    max-width: 100%;
  }
  .get-started-now-btn {
    height: 55px;
    width: 210px;
    font-size: 18px;
  }
  .about_mytute {
    margin-left: 0;
  }
  .helpContent h2 {
    font-size: 22px !important;
  }
  .helpContent p {
    font-size: 15px !important;
  }
  .text-left.helpContent.mt-2.left_stepper {
    margin-bottom: 11px;
  }
  .helpDive5 {
    width: 60px;
    height: 60px;
  }
  .font-18 {
    font-size: 16px;
  }
  .css-iqp6n2-MuiAvatar-root {
    width: 90px;
    height: 90px;
  }
  .home_banner_b {
    max-width: 896px;
  }
  .home_laptopImg {
    margin-top: 230px !important;
  }
  .redBgImg {
    top: 20% !important;
  }
  .storeImg {
    display: flex;
    padding-bottom: 80px;
  }
  .row_margin {
    margin-bottom: 50px;
  }
  .justify-content-center.text-center.my-5 .helpbg_1 {
    display: none;
  }
  .sideNavbar ul li {
    font-size: 16px;
  }
  .sideNavbar ul {
    padding: 0 8px;
  }
  .sideNavbar > img {
    width: 90%;
  }
  .doctor-img-box img {
    width: 90%;
  }
  .ms-5 img {
    margin-left: -50px;
  }
  .search-bar {
    width: 90%;
    padding: 5px;
  }
  .solution_bg {
    background-size: 90%;
    margin-top: 88px;
  }
  .homeBgRightangle {
    display: none;
  }
  .howCanDawaeeHelp_main {
    margin-top: -100px;
  }
  .people-says-about-us {
    height: 550px !important;
  }
  .dHelpPara {
    padding-bottom: 50px;
  }
  /* About us starts */
  .about_mytute {
    margin-top: 100px;
  }
  .aboutVistDoctor_sect .row {
    row-gap: 50px;
  }
  .aboutboxx,
  .aboutboxx2 {
    width: 195px;
    height: 170px;
  }
  .red_box {
    width: 235px;
    height: 110px;
  }
  .problemWorth_Para {
    margin-top: 50px;
  }
  /* About us ends */
  /* Login page start */
  h5 {
    font-size: 18px;
  }
  .LoginBgImg {
    left: 79%;
    top: -2px;
    width: 20%;
  }
  .loginForm {
    padding: 0 25px;
  }
  .LoginForm {
    margin: 50px 0px;
  }
  .loginLogo {
    margin: 20px 0;
  }
  /* Login page ends */
  /* Persnol Information starts */
  .setting_fileupload {
    width: 38%;
  }
  .persnolInfo_upload {
    padding-left: 78px;
  }
  .otp-img2 > img {
    display: none;
  }
  .consult_doctor {
    padding-top: 160px;
  }
  /* Persnol Information ends */
  /* Dashboard starts */
  .dashborad-profile-inner-box a {
    padding: 6px 10px;
    margin-right: 4px;
  }
  .Dashboardactive {
    width: 175px !important;
  }
  .ProfileRightBoxSeeAll h6 {
    font-size: 18px;
  }
  .p-d-null-data p {
    text-align: center;
  }
  .font20 {
    font-size: 16px !important;
  }
  .doctor_dashboard_inbox {
    padding: 4px !important;
  }
  .font26 {
    font-size: 17px !important;
  }
  .doctor_dashboard_earnings,
  .doctor_dashboard_statistics {
    padding: 12px !important;
  }
  #chart {
    transform: scale(0.8);
  }
  .week-box {
    transform: scale(0.8);
    margin-top: 105px;
  }
  .bold_custom {
    font-size: 24px;
  }
  .doctor_dashboard_newyork_block h6 {
    font-size: 16px !important;
  }
  /* Dashboard ends */
  /* Setting page starts */
  .setting_fileupload {
    margin-left: 80px;
    width: 53%;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .UploadProfile p {
    margin-left: 88px;
  }
  h2.text-white {
    font-size: 20px !important;
  }
  /* Setting page ends */
  .appointment_main input {
    font-size: 12px;
  }
  .appointment_main .form-select {
    font-size: 12px;
    padding: 10px 3px;
  }
  .modal-content {
    margin: 0 auto;
    width: 90%;
  }
  .MedicalH li {
    font-size: 11px !important;
  }
  .ProfileRightBoxTop h4 {
    font-size: 14px !important;
  }
  .MedicalHead p {
    font-size: 13px !important;
  }
  .schedule-row {
    gap: 0px !important;
  }
  /* .px-290 {
                      width: 265px !important;
                  } */
  .profile-list {
    font-size: 13px !important;
  }
  .MidLabBtn button {
    width: 245px !important;
  }
  .px-290 {
    width: 264px !important;
  }
  .proceed_btn a {
    padding: 12px 14px !important;
    /* font-size: 18px; */
  }
  .proceed_btn {
    margin-top: 29px !important;
  }
  .redBgImg_Right {
    top: 20%;
  }
  .home_laptopImg_Right {
    margin-top: 182px;
  }
  .apexcharts-canvas {
    right: 48px !important;
  }
  .dashborad-profile-box h6 {
    font-size: 12px;
}
}

@media (max-width: 992px) {
  .remove-gutter {
    margin: 0 !important;
  }
  .pharmacy-card-box {
    column-gap: 80px;
  }
  .doctor_dashboard_newyork_block {
    /* display: none; */
  }
  .doctor_dashboard_inbox {
    margin-top: 30px;
  }
  .msg-img-box {
    width: 100px;
    height: 100px;
  }
  /* collapse .navbar starts */
  .sideNavbar {
    position: fixed;
    left: 0px;
    z-index: 1;
  }
  .textBlack {
    font-size: 12px;
  }
  .web-color {
    font-size: 12px;
  }
  .sideNavbar .ms-5 > img {
    width: 56% !important;
    margin: 0 auto;
  }
  .inbox_wrapper h5 {
    padding: 12px;
  }
  .sideNavbar {
    border-radius: 0;
    max-width: 220px;
  }
  .nav__cont {
    position: fixed;
    width: 60px;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 100;
    background-color: #202020;
    overflow: hidden;
    transition: width 0.1s ease;
    cursor: pointer;
    box-shadow: 4px 7px 10px rgba(0, 0, 0, 0.4);
    overflow-y: auto;
  }
  /* .nav__items {
                      padding-bottom: 4rem;
                      font-family: 'roboto';
                  } */
  .navs {
    list-style-type: none;
    color: white;
  }
  .navs:first-child {
    padding-top: 1.5rem;
  }
  .nav__cont a {
    position: relative;
    display: block;
    top: -35px;
    padding-left: 30px;
    padding-right: 15px;
    transition: all 0.3s ease;
    margin-left: 26px;
    margin-right: 10px;
    text-decoration: none;
    color: white;
    font-weight: 100;
    font-size: 18px;
  }
  a:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 2px;
    background: radial-gradient(
      circle at 94.02% 88.03%,
      #54a4ff,
      transparent 100%
    );
    opacity: 0;
    transition: all 0.5s ease;
    z-index: -10;
  }
  a:hover a:after {
    opacity: 1;
  }
  .px-290 {
    width: 266px !important;
  }
  .schedule {
    margin-top: 30px !important;
  }
  .navbar-nav {
    margin-left: -12px !important;
  }
  .schedule {
    width: 100% !important;
    max-width: 97px !important;
  }
  .for-mobile-screen {
    display: block;
    margin-top: 20px;
  }
  .for-large-screen {
    display: none;
  }
  .dashboard-rightSide {
    margin-left: 135px;
    margin-top: 55px;
    margin-bottom: 30px;
  }
  .doctor-img-box {
    width: 160px;
    margin-left: 40px;
  }
  /* .dashboard-mid-top-section {
    
    margin-left: 79px;
  } */
  .doctor-Dashboard-mid-section.mt-5 {
    margin-left: 113px;
  }
  .footertxtCent2 {
    text-align: center;
  }
  /* collapse .navbar ends */
  .BannerRound {
    display: none;
  }
  .soultion_card_cont {
    margin-left: 16px;
  }
  .wallet-icon {
    margin-right: 0px;
  }
  ul li img {
    margin-right: 36px;
  }
  .schdulors.row {
    /* grid-gap: 30px; */
    /* grid-gap: 7px; */
    gap: 27px;
  }
  .notification-menu
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
        top: 12px !important;
        left: 446px !important;
    }
    /* .dashboard-visitDoctors-box-top {
      gap: 20px !important;
  } */
}

@media (max-width: 991px) {
  .pharmacy-card {
    margin-left: 90px;
  }
  .pharmacy-card-box {
    column-gap: 122px;
  }
  .category_row {
    margin-left: 0px;
    grid-column-gap: 40px;
    column-gap: 60px;
}
  .pharmacy_box_2 {
    width: 360px;
  }
  .navbar-dark .navbar-toggler-icon,
  .navbar-light .navbar-toggler-icon {
    background-image: url("../images/menu-icon.png");
    font-size: 20px;
  }
  .margin_zero {
    margin: 0;
  }
  .navbar .container {
    margin-left: 37px;
  }
  .navbarBrand2 button {
    margin-left: 0 !important;
  }
  .doctor_dashboard_newyork_block {
    margin: 20px 0;
  }
  .dashboard-topbar-rightside-box {
    height: 100%;
    flex-direction: column;
  }
  .dashboard-topbar-rightside-text span {
    color: black;
  }
  /* .navbar>div {
                      justify-content: end !important;
                  } */
  .logo_container .navbar-brand img {
    text-align: left;
  }
  .navbar-nav {
    text-align: center;
    margin-left: unset;
  }
  /* footer starts */
  .footer-logo {
    margin: 0 auto;
  }
  .text-left {
    text-align: center;
    margin: 0 auto;
  }
  .footertxtCent2 {
    margin: 0 auto;
    width: 100%;
  }
  /* .row>.col-md-6 {
                      flex-shrink: 0;
                      width: 100%;
                      max-width: 100%;
                  } */
  .mt-4.d-flex.align-items-center {
    justify-content: center;
    margin: 20px 0;
  }
  /* footer ends */
  /* payment starts */
  .paymentCards .balanceCard,
  .withdrwaRow .balanceCard {
    width: 90%;
    margin-left: 78px;
  }
  .mywalletPara .small {
    padding: 0 90px;
  }
  .custmTable {
    margin-left: 77px;
    width: 90%;
  }
  /* .dashboard h5 {
                      margin-left: 9% !important;
                  } */
  /* payment ends */
  /* Dashboard screen starts */
  .doctor_dashboard_newyork_block,
  .doctor_dashboard_inbox {
    width: 90%;
    margin-left: 75px;
  }
  .doctor_dashboard_earnings {
    width: 90% !important;
    margin-top: 20px;
    margin-left: 75px;
  }
  .week-box {
    margin-top: -170px;
  }
  .doctor_dashboard_statistics {
    width: 90% !important;
    margin-left: 74px;
  }
  .ProfileRightBox {
    width: 92%;
    margin-left: 62px;
  }
  /* Dashboard screen ends */
  /* Appointment table starts */
  .appointment_searchBars {
    width: 96%;
  }
  .appointmentsTables {
    width: 80%;
  }
  /* Appointment table ends */
  /* manage-schedule screen starts */
  .schdulors {
    margin-left: 58px;
    margin-bottom: 20px;
  }
  /* manage-schedule screen ends */
  /* ChatScreen starts */
  .chats-left-box {
    margin-bottom: 20px;
  }
  .chats-left-box,
  .chats-right-box {
    width: 100%;
    /* margin-left: 60px; */
  }
  .chat-section .chats-right-box .chat-header {
    padding: 50px 3% !important;
  }
  /* ChatScreen ends */
  .dashboadrd_setting {
    width: 90%;
    margin-left: 78px;
  }
  /* .dashboard h5 {
                      margin-left: 10% !important;
                  } */
  .dashboard-visitDoctors-box {
    margin-bottom: 20px;
  }
  .Appointments-box {
    height: 655px;
  }
  .dashborad-profile-img-box {
    width: 100px;
    height: 100px;
  }
  .dashborad-profile-box {
    margin: 50px 0;
  }
  .SelectCityMain h5 {
    margin-left: 0 !important;
  }
  .addressImg_col {
    width: 30%;
  }
  .address_col {
    width: 70%;
  }
  .dashboard-visitDoctors-box-top {
    row-gap: 20px;
  }
  .whatsholudIVaccine p {
    text-align: left !important;
  }
  .statistics_wrapper .heading_month {
    margin-left: 30px;
  }
  .apexcharts-canvas {
    right: 0px !important;
  }
  .chart-main-div {
    padding: 0px 30px;
  }
  .footer-socail-icon {
    justify-content: center;
  }
  .footertxtCent2 {
    margin-top: 20px;
  }
  .footertxtCent3 h6 {
    margin-top: 0 !important;
  }
  .footer-div-xx {
    margin-top: 0 !important;
  }
  .notification-menu .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
    top: 260px !important;
    left: 415px !important;
}
.appointmentsTables {
    width: 96%;
}
.Schedule-x h5 {
    margin-left: 62px;
}
.withdrwaRow .balanceCard {
    width: 100% !important;
    margin-left: 0px !important;
}
.appointmentsTables {
    width: 100% !important;
    margin-left: 0px !important;
}
.dashboadrd_setting {
    width: 100%;
    margin-left: -12px;
}
.dashboard-visitDoctors-box-top {
  gap: 20px !important;
}
.pharmacy-card {
  margin-left: 25px;
}
}
@media (max-width: 991px){
.pharmacy_box_2 {
    width: 334px;
}
}
@media (max-width: 768px) {
  .pharmacy-card-box {
    column-gap: 90px;
  }
  .pharmacy-card {
    margin-left: 40px;
  }
  .text-icon-x {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .pharmacy_box_2 {
    width: 280px;
  }
  .category_row {
    margin-left: 0;
  }
  .profile_screen {
    margin: 0 auto;
  }
  .apponitment_tableee {
    width: 100% !important;
  }
  /* .select_place {
                      margin-left: 83px;
                  } */
  .ordersm {
    margin-left: 83px;
  }
  .ordersm {
    margin-left: -296px;
  }
  .aboutVistDoctor_sect {
    margin-top: 0px !important;
  }
  .chat-section .chats-left-box-messages {
    height: auto !important;
  }
  .appointmentsTables {
    width: 96% !important;
  }
  .redBgImg_Right {
    display: none;
  }
  .MedicalHead {
    display: flex;
    /* justify-content: center; */
    margin-bottom: 29px !important;
  }
  /* .profile_tablle {
                      white-space: nowrap;
                  } */
  .icons_profile {
    display: flex;
    /* justify-content: center; */
  }
  .dashboard-topbar-rightside-box {
    margin: 0 !important;
  }
  #google_translate_element .skiptranslate.goog-te-gadget {
    font-size: 0px;
    margin-left: 30px;
  }
  .goog-te-gadget .goog-te-combo {
    margin: 10px 0 !important;
  }
  /* Navbar Css starsts */
  #basic-navbar-nav {
    text-align: center;
  }
  .navbar-nav {
    justify-content: center;
    align-items: center;
  }
  /* Navbar Css ends */
  /* Home section starts  */
  .BannerRound {
    max-width: 8%;
    right: 1%;
    top: 146px;
  }
  .soultion_card_cont > .row {
    justify-content: center;
  }
  .homeBgRightangle {
    max-width: 5%;
    right: 46%;
    top: 51px;
  }
  .home_first_sec_left {
    text-align: center;
  }
  .heading p {
    margin: 0 auto;
    max-width: 100%;
    text-align: center;
  }
  .home_first_sec_left {
    margin-top: 40px;
  }
  .quoteImg {
    display: none;
  }
  .storeImg {
    justify-content: center;
  }
  .home-right-first {
    transform: scale(0.8) !important;
  }
  .storeImg {
    padding-bottom: unset;
  }
  .solution_bg {
    margin-top: 0px;
  }
  .redBgImg {
    background-image: unset !important;
  }
  .about_mytute,
  .about_mytute p {
    text-align: center;
  }
  .circle {
    position: relative;
    left: 30px;
  }
  .dawaee_help_img > img {
    transform: translate(0.8);
  }
  .dawaee_help_img > img {
    transform: scale(0.8);
  }
  .people-says-about-us {
    background-image: unset;
    background-color: #0b30a9;
  }
  .quoteImg_main {
    display: flex !important;
    justify-content: center;
    align-items: center !important;
    margin: 20px 0px;
  }
  .people-says-about-us {
    height: 500px !important;
  }
  .book_doctor_headings {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    margin: 30px 0px;
  }
  .book_doctor_headings p {
    text-align: center !important;
  }
  .book_dr_img {
    text-align: center;
  }
  .storeImg {
    justify-content: space-evenly;
  }
  .ScrollIcon {
    margin-top: -130px;
  }
  #solution {
    margin-top: -90px;
  }
  .card_cutm {
    flex-basis: 90%;
  }
  .solution_In_one_App_parrent {
    display: flex;
    justify-content: center;
    margin: 15px 0;
  }
  .howCanDawaeeHelp_main {
    margin-top: 24px;
  }
  .appStore_googlePlay {
    margin-top: -100px;
    display: flex;
    justify-content: space-evenly !important;
  }
  .mainDiv_circle {
    margin-top: 35px;
  }
  /* Home section ends */
  /* ######Footer section starts######## */
  .footertxtCent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin: 20px auto; */
  }
  .justify-content-center.text-center.my-5 {
    display: flex;
    justify-content: space-evenly !important;
  }
  /* ######Footer section ends######## */
  /* About page starts */
  .home_first_sec_left p {
    text-align: center;
  }
  .marginTop {
    margin-top: 10px;
  }
  .Problem_Worth {
    background-color: #0b30a9;
    text-align: center;
  }
  .Problem_Worth::before {
    background-image: none;
  }
  .about_boxsRow {
    position: unset;
    justify-content: center;
  }
  .bg_red {
    background: unset;
    position: unset;
  }
  .pc_img {
    position: unset;
    text-align: center !important;
  }
  .pc_img img {
    position: unset;
    margin-top: 50px;
  }
  .about_first_sect {
    flex-direction: column-reverse;
  }
  .home-right-first {
    transform: scale(0.7) !important;
    margin-top: -127px;
  }
  .center_small {
    padding-right: 0;
  }
  .Problem_Worth {
    height: 600px;
    padding: 50px 18px;
    margin: 30px 0;
  }
  .home_laptopImg {
    margin-top: -11px;
  }
  .aboutVistDoctor_sect .row {
    margin: 0 auto;
  }
  .unique_selling,
  .unique_selling > p {
    text-align: center;
  }
  .about_business,
  .about_business > p {
    padding: 0px;
    text-align: center;
  }
  .abtBusiness_Stratgies .row {
    justify-content: center;
  }
  .businessToCustomer_inner {
    text-align: center;
  }
  .businessToCutomers_main .businessToCustomer_inner p {
    text-align: center;
  }
  .businessToCustomer_inner > div {
    display: flex;
    justify-content: center;
  }
  .mobileBg img {
    margin: 30px auto;
    width: 70%;
  }
  .Problem_Worth::before {
    position: unset;
  }
  .center_small {
    padding-right: unset !important;
  }
  .about_first_sect {
    margin-top: -90px;
  }
  .aboutTable_thead th {
    font-size: 20px !important;
  }
  td {
    font-size: 18px;
  }
  .home_laptopImg {
    margin-top: 50px !important;
  }
  .aboutVistDoctor_sect .row {
    row-gap: 20px;
  }
  .uniueSelling_proposition {
    margin: 50px 0;
  }
  .about_boxsRow {
    gap: 20px;
  }
  .unique_selling ul > li {
    font-size: 16px;
    text-align: left;
  }
  .Problem_Worth p {
    text-align: center !important;
  }
  .mobileImg_text {
    text-align: center !important;
  }
  .mobileImg_text p {
    text-align: center !important;
  }
  .squareBox {
    margin: 0 auto;
  }
  .aboutboxx,
  .aboutboxx2 {
    margin: 0 auto;
  }
  /* About page ends */
  /* Login starts */
  .loginImg {
    margin-top: -20px;
  }
  .latest_update_heading {
    margin-top: 20px;
  }
  .LoginForm {
    margin: 0px 0px !important;
  }
  /* Login ends */
  /* Signup starts */
  .loginFormSection {
    border-radius: 0 !important;
  }
  .card-container-x {
    margin-bottom: 20px;
  }
  .loginImg > img {
    display: none;
  }
  .loginImg {
    margin-top: -121px;
  }
  .loginImg {
    padding: 0;
  }
  /* Signup ends */
  /* Sidebar css starts */
  /* collapse .navbar starts */
  /* collapse .navbar ends */
  .doctor_dashboard_newyork_block {
    margin-left: 85px !important;
    width: 85% !important;
  }
  .dashboard-topbar-rightside-box {
    margin: 13px 2px -4px 0;
  }
  .doctorRedBg {
    margin-top: 55px !important;
  }
  .consult_doctor {
    padding-top: 0px;
  }
  .doctor_dashboard_inbox {
    margin-left: 85px;
    width: 85% !important;
    padding: 20px !important;
  }
  #chart {
    margin-left: 40%;
    transform: unset;
    margin-top: -155px;
  }
  .week-box {
    margin-top: -125px;
    transform: unset;
  }
  .doctor_dashboard_earnings,
  .doctor_dashboard_statistics {
    width: 85% !important;
    margin-left: 85px;
    margin-top: 20px;
  }
  .ProfileRightBox {
    margin-left: 72px;
    margin-bottom: 20px;
    width: 88% !important;
  }
  .navLink {
    margin-left: 26px;
  }
  ul li img {
    width: 30px;
  }
  /* Sidebar css ends */
  /* Setting page starts */
  .dashboadrd_setting {
    width: 100% !important;
    margin-left: 0px !important;
  }
  /* .dashboard h5 {
                      margin-left: 15%;
                  } */
  /* Setting page ends */
  /* .navbar-dark .navbar-toggler-icon,
              .navbar-light .navbar-toggler-icon {
                  background-image: url('../images/menu-icon.png');
                  font-size: 18px;
              } */
  .navbar-toggler {
    background-color: rgb(49, 48, 48);
  }
  .navbar-toggler {
    margin-left: auto;
    background-color: #efefef;
  }
  /* .appointTable {
                      margin: 0 70px !important;
                  } */
  th {
    white-space: no-wrap !important;
  }
  .appointment_searchBars {
    margin: 20px 0px !important;
    width: 100%;
  }
  .input-group {
    width: 97%;
    margin: 0 auto;
  }
  .paymentCards .balanceCard,
  .withdrwaRow .balanceCard {
    margin: 10px 100px;
  }
  .mywalletPara p.small {
    text-align: center;
    margin: 0 65px;
  }
  .custmTable {
    width: 590px;
    margin: 0 100px;
  }
  /* Schedule page starts */
  .schdulors {
    margin-left: 80px;
    margin-bottom: 30px;
  }
  /* Schedule page ends */
  /* Chatscreen starts  */
  .chats-right-box,
  .chat-section .chats-left-box {
    /* margin-left: 88px; */
    margin-bottom: 20px;
  }
  h6 {
    font-size: 14px;
  }
  .chat-section .chats-right-box {
    padding: 30px 0;
  }
  .chat-section .chats-right-box .chat-footer .send-btn {
    padding: 10px !important;
  }
  .chat-section .chats-right-box .chat-footer .send-btn .icon {
    width: 24px !important;
    height: 20px !important;
  }
  .chat-section .chats-right-box .chat-timeline .time {
    font-size: 14px !important;
  }
  /* Chatscreen ends  */
  .notification-menu
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
    width: 52% !important;
    transform: translate(-20%, 38px) !important;
  }
  .loginFormSection {
    margin: 20px 0;
  }
  /* .navbar-toggler {
                      margin-left: 0px;
                  } */
  .statistics_wrapper > .row {
    flex-direction: column;
  }
  .statistics_wrapper > .row > div:nth-child(1) {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
  #chart {
    transform: scale(0.8) !important;
    margin: 0;
    margin-top: -30px;
    margin-left: 87px;
  }
  .apexcharts-canvas {
    all: unset;
  }
  .small-modal-x .modal-content {
    width: 80% !important;
  }
  .UploadProfile p {
    margin-left: 53px !important;
    text-align: center !important;
  }
  .opt-outer-div {
    margin: 360px 0 !important;
    width: 100%;
    margin-left: -42px !important;
  }
  .chatbox-col {
    max-width: 100% !important;
    width: 100% !important;
  }
  .doctor-detail-section1 {
    /* display: flex;
                      align-items: center; */
    margin-left: 0px;
    margin-bottom: 20px;
  }
  .doctor-detail-sectionss p {
    font-size: 12px !important;
  }
  .navbar {
    padding: 0px 18px !important;
  }
  .Schedule-x h5 {
    margin-left: 54px !important;
}
  .schdulors {
    margin-left: 50px !important;
    /* margin-bottom: 30px; */
  }
  .schdulors.row {
    /* grid-gap: 30px; */
    /* gap: 21px !important; */
    gap: 48px !important;
  }
  .withdrwaRow .balanceCard {
    width: 100% !important;
    margin: 10px 0px !important;
  }
  .appointmentsTables {
    width: 100% !important;
  }
  .custmTable {
    /* width: 590px; */
    margin: 0 0px !important;
  }
  .setting-x h5 {
    margin-left: 3% !important;
  }
  .summary_block {
    /* margin-left: 40px; */
  }
  .proceed_btn {
    /* margin-top: 29px !important; */
    margin-bottom: 50px;
  }
  .order_details {
    margin-left: 0px !important;
    /* margin-top: 40px; */
  }
  .dashboard-rightSide {
    margin-left: 62px;
  }
  .soultion_card_cont {
    margin-left: 0px;
  }
  .home_laptopImg_Right img {
    width: 85% !important;
  }
  .doctor-mobile-screen {
    display: block;
  }
  .doctor-large-screen {
    display: none;
  }
  .doctor_dashboard_newyork_block {
    display: none;
  }
  div#google_translate_element {
    margin-right: 40px;
  }
  .dashboard-icons {
    font-size: 30px;
    /* color: #6a6a6a; */
    margin-right: 14px;
  }
  #basic-button {
    margin-left: 0px;
  }
  .dashborad-profile-box {
    margin: 5px 0;
  }
  .footertxtCent.footertxtCent3.col-lg-4.col-md-6.col-sm-12 {
    margin-top: -98px;
  }
  .storeImg {
    gap: 10px !important;
  }
  .notification-menu .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
    top: 291px !important;
    left: 212px !important;
}
.navbar {
    padding: 0px 0px !important;
}
.statistics_wrapper > .row > div:nth-child(1) {
    gap: 236px !important;
    justify-content: flex-start;
}
#chart {
    margin-left: 133px !important;
}
.login-btn {
    border: none !important;
    color: #ffffff !important;
    margin-right: 0px !important;
}
.appointmentsTables {
    width: 100% !important;
    margin-left: 10px !important;
}
  /* .footertxtCent3 h6{
        margin-top: 0 !important;
    }
    .footer-div-xx{
        margin-top: 0 !important;
    } */
}

@media (max-width: 767px) {
  .product_select {
    width: 30%;
  }
  .chats-left-box,
  .chats-right-box {
    width: 90%;
    margin-left: 70px;
  }
  .about_mytute,
  .about_mytute p {
    margin-bottom: 20px;
  }
  .home_laptopImg_Right img {
    width: 50% !important;
  }
  .whatsholudIVaccine h3 {
    text-align: left;
  }
  .appointment_searchBars {
    width: 92%;
    margin: 21px 52px !important;
}
.appointmentsTables {
    width: 90% !important;
    margin-left: 63px !important;
}
.withdrw h5 {
    margin-left: 53px !important;
}
.withdrwaRow .balanceCard {
    width: 93% !important;
    margin: 10px 54px !important;
}
.invoice_activity.row h5 {
    margin-left: 57px !important;
}
.appointmentsTables.custmTable.row {
    width: 93% !important;
    margin-left: 56px !important;
}
.dashboadrd_setting {
    width: 93% !important;
    margin-left: 53px !important;
}
.setting-x h5 {
    margin-left: 67px !important;
}
.setting_fileupload {
    margin-left: 0px !important;
    width: 50%;
    margin: 15px auto !important;
  }
  .UploadProfile{
    margin-left: 0px !important;
    /* text-align: center !important; */
  }
  .doctor-detail-sectionss {
    margin-left: 63px !important;
}
.doctor-detail-section1 {
    margin-left: 52px;
}
.doctorDetail-fees-card , .doctorDetail-experience-card {
    margin-left: 57px !important;
}
.doctor-detail-tabs-card {
    margin-left: 58px !important;
}
.lab-servicers h5{
    margin-left: 58px !important;
}
.doctor-Dashboard-mid-section.mt-5 {
  margin-left: 54px;
}
.category_row {
  margin-left: 49px;
}
.product_select.col-md-3 {
  margin-left: 61px !important;
  width: 92%;
}
.pharmacy-card {
  margin-left: 86px !important;
}
}

@media (max-width: 600px) {
  .chats-left-box,
  .chats-right-box {
    width: 86% !important;
    /* margin-left: 70px; */
  }
  .dashboadrd_setting {
    width: 90% !important;
    margin-left: 57px !important;
  }
  .ProfileRightBox {
    margin-left: 55px;
    width: 90% !important;
  }
  .doctor_dashboard_inbox {
    margin-left: 68px;
    width: 86% !important;
  }
  .doctor_dashboard_earnings,
  .doctor_dashboard_statistics {
    width: 86% !important;
    margin-left: 69px;
  }
  #chart {
    margin-left: 31px;
  }
  .statistics_wrapper > .row > div:nth-child(1) {
    gap: 162px;
    justify-content: flex-start !important;
  }
  .schdulors.row {
    /* grid-gap: 30px; */
    /* gap: 21px !important; */
    grid-gap: 48px !important;
    gap: 37px !important;
}
.withdrwaRow .balanceCard {
    width: 92% !important;
    margin: 10px 54px !important;
}
.appointmentsTables.custmTable.row {
    width: 91% !important;
    margin-left: 56px !important;
}
.setting_fileupload {
    margin: 15px auto;
}
.UploadProfile {
    margin-left: 0px !important;
}
.UploadProfile p {
    margin-left: 0px !important;
    text-align: center !important;
}
.week-box {
    margin-top: -22px !important;
    /* transform: unset; */
}
#chart {
    margin-left: 32px !important;
}
.doctor-img-box {
  width: 148px;
  margin-left: 0px;
}
.doctor-Dashboard-mid-section.mt-5 {
  margin-left: 58px;
}
.SelectCityMain h5{
  margin-left: 50px !important;
}
.SelectCityMain p{
  margin-left: 50px;
}
.SelectCityBtnDiv {
  width: 29%;
}
.preferences-x-c {
  
  margin-left: 31px;
}
.MidLabBtn button {
  width: 222px !important;
  margin-left: 57px;
}
.schedule-row.row {
  gap: 31px !important;
  margin-left: 58px !important;
}
.heading-schedul-x h5{
  margin-left: 55px;
}
.pharmacy_box_2 {
  width: 238px;
}
.category_row {
  column-gap: 47px;
}
.pharmacy-card {
  margin-left: 63px !important;
}
.product_select.col-md-3 {
  margin-left: 61px !important;
  width: 89%;
}
}


@media (max-width: 540px) {
  .apponitment_tableee {
    width: 460px !important;
  }
  .MedicalHead {
    margin-left: 50px !important;
  }
  ul.MedicalH {
    margin-left: 31px;
  }
  .appointment_searchBars {
    margin: 20px 46px !important;
    width: 90% !important;
}
  .pharmacy-card {
    width: 70%;
    margin-left: 103px;
  }
  img.card-img-top {
    width: 100%;
    height: 90%;
  }
  .pharmacy_box_2 {
    width: 100% !important;
    margin: -5px -22px !important;
  }
  .category_row {
    margin-left: 83px;
  }
  .category_row > h5 {
    margin-left: -23px;
  }
  .appointmentsTables {
    width: 87% !important;
    margin-left: 11% !important;
  }
  #google_translate_element .skiptranslate.goog-te-gadget {
    margin-left: 30px;
    margin-top: -27px !important;
  }
  /* .appointmentsTables {
                      width: 90%;
                  } */
  .sideNavbar ul {
    padding: 0px 2px;
  }
  .nav__cont {
    width: 52px;
  }
  .dashboard-mid-top-section {
    width: 80%;
    margin: 0 auto;
  }
  .dashboard-visitDoctors-box,
  .dashboard-Pharmacy-box,
  .dashboard-covid19-box,
  .dashboard-rightSide {
    width: 80%;
    margin: 20px 75px;
  }
  .Appointments-box-top {
    justify-content: space-evenly;
  }
  .order_table {
    width: 400px;
    overflow-x: scroll;
    margin-left: 80px;
  }
  .chat-section .chats-left-box {
    height: auto !important;
  }
  .doctorRedBg {
    margin-top: -100px !important;
  }
  .apponitment_tableee {
    width: 406px !important;
  }
  .home_laptopImg {
    margin-top: 18px !important;
  }
  .dashboard-topbar-rightside {
    margin-top: -88px;
  }
  .goog-te-gadget .goog-te-combo {
    margin: 16px 0 !important;
  }
  /* Home page starts */
  .circle {
    left: 0px;
  }
  .aboutVistDoctor_sect {
    margin-top: 0px;
  }
  .about_mytute {
    margin-top: 40px !important;
  }
  /* .footertxtCent3 {
        margin-top: -60px;
    } */
  .row .home_first_sec_left > img:nth-child(2) {
    display: none;
  }
  .row .home_first_sec_left > img:nth-child(3) {
    display: none;
  }
  .solution_bg {
    background-image: unset;
  }
  .circle {
    width: 45px;
    height: 45px;
  }
  .line::before {
    left: 22px;
    top: 38px;
  }
  .Problem_Worth p {
    text-align: center;
  }
  .mobileImg_text,
  .mobileImg_text p {
    text-align: center;
  }
  /* Home page ends */
  /* About us page starts */
  .listItmesss {
    margin-top: 10px;
  }
  .red_box {
    width: 180px;
    height: 90px;
  }
  .unique_selling ul > li {
    font-size: 14px;
  }
  /* About us page ends */
  /* Login starts */
  .loginImg > img {
    display: none;
  }
  .loginForm {
    padding: 0px 0px;
  }
  .loginBtn {
    padding: 10px;
    color: #ffffff !important;
    font-size: 18px;
  }
  .LoginForm {
    margin: 20px 0px;
  }
  /* Login ends */
  /* Persnol Information starts */
  .persnolInfo_upload {
    padding-left: 0;
  }
  .setting_fileupload {
    margin: 20px auto;
  }
  .UploadProfile p {
    text-align: center;
  }
  /* Persnol Information ends */
  .week-box {
    margin-top: 34px;
  }
  .font20 {
    font-size: 14px !important;
  }
  /* Setting page starts */
  .setting_fileupload {
    width: 60%;
  }
  .UploadProfile p {
    margin-left: -29px;
  }
  /* Setting page ends */
  .paymentCards .balanceCard,
  .withdrwaRow .balanceCard {
    margin: 10px 103px;
    width: 350px;
  }
  .custmTable {
    width: calc(100% - 61px) !important;
    margin: 20px 100px;
  }
  .custmTable thead tr th,
  .AppointmentTables tbody tr td {
    padding: 17px;
    white-space: nowrap !important;
  }
  .doctor_dashboard_newyork_block,
  .doctor_dashboard_inbox,
  .doctor_dashboard_earnings,
  .doctor_dashboard_statistics {
    margin-left: 78px !important;
    width: 82% !important;
  }
  #google_translate_element {
    margin-top: 18px;
  }
  .bell-icon-link {
    margin-top: 38px;
  }
  .ProfileRightBox {
    margin-left: 45px;
    margin-bottom: 20px;
    width: 92% !important;
  }
  /* Chatscreen starts  */
  .chat-section .chats-right-box .chat-footer .message-input::placeholder {
    font-size: 14px;
  }
  .chat-section .chats-right-box .chats-container .left-message .message {
    margin-left: 14px !important;
    margin-top: 0px !important;
  }
  /* Chatscreen ends  */
  h4 {
    font-size: 18px !important;
  }
  .modal-content {
    width: 100% !important;
  }
  #google_translate_element .skiptranslate.goog-te-gadget {
    margin-top: -78px;
  }
  .dashboadrd_setting {
    width: 100%;
    margin-left: 68px;
  }
  .UploadProfile p {
    /* margin-left: -20px !important; */
    text-align: center !important;
  }
  .opt-outer-div {
    margin: 500px 0 !important;
    margin-left: -38px !important;
  }
  .dashboadrd_setting {
    width: 88% !important;
    margin-left: 57px !important;
  }
  .preferences-x-c {
    /* display: flex;
                      flex-wrap: wrap; */
    margin-left: 20px !important;
  }
  .SelectCityMain h5 {
    margin-left: 36px !important;
  }
  .SelectCityMain p {
    margin-left: 35px !important;
  }
  .SelectCityBtnDiv {
    width: 30% !important;
    margin-right: 5px !important;
  }
  .dashboard h5 {
    margin-left: 9% !important;
  }
  .doctorsNearsCard {
    width: 90% !important;
    margin-left: 52px !important;
  }
  .doctor-detail-section1 {
    margin-left: 50px !important;
    /* margin-bottom: 20px; */
  }
  .doctor-detail-sectionss {
    margin-left: 62px !important;
  }
  .doctorDetail-fees-card {
    margin-left: 50px !important;
  }
  .doctorDetail-experience-card {
    margin-left: 50px !important;
  }
  .doctor-detail-tabs-card {
    margin-left: 50px !important;
  }
  .text-center {
    /* margin-top: 30px !important; */
    margin-left: 50px !important;
  }
  .MidLabBtn button {
    width: 217px !important;
    /* height: 50px; */
  }
  .doctorDetail-fees-card h5 {
    margin-left: 0px !important;
  }
  .doctorDetail-experience-card h5 {
    margin-left: 0px !important;
  }
  .px-290 {
    width: 147px !important;
  }
  .apppointmentDest_closeBtn > button,
  .areYouSureBtn > button {
    padding: 12px 0px !important;
  }
  .schedule-row {
    margin-left: 50px !important;
    gap: 25px !important;
  }
  .schedule .schedule-btn-blue {
    background-color: #0b30a9;
    font-size: 14px !important;
  }
  .select_place {
    margin-left: 50px !important;
  }
  .apponitment_tableee {
    width: 460px !important;
  }
  .icons_profile {
    width: 50% !important;
  }
  .px-290 {
    width: 213px !important;
  }
  .Schedule-x h5 {
    margin-left: 53px !important;
  }
  .apppointmentDest_closeBtn > button,
  .areYouSureBtn > button {
    padding: 12px 37px !important;
  }
  .schedule button {
    width: 102px !important;
    /* height: 55px; */
  }
  .withdrwaRow .balanceCard {
    width: 91% !important;
    margin: 10px 46px !important;
  }
  .mywalletPara p.small {
    /* text-align: center; */
    margin: 20px 0px !important;
  }
  .setting-x h5 {
    margin-left: 14% !important;
  }
  .checkout-x h5 {
    margin-left: 10px;
  }
  .checkout_table {
    margin-left: 35px !important;
  }
  .summary_block {
    margin-left: 56px !important;
  }
  .proceed_btn {
    margin-left: 0px !important;
  }
  .checkout_table {
    width: 90% !important;
  }
  .shipping_details_block {
    margin-left: 50px !important;
  }
  .order-x h5 {
    margin-left: 50px !important;
  }
  .doctor-Dashboard-mid-section.mt-5 {
    margin-left: 0;
    margin-bottom: 50px;
  }
  .doctor-img-box img {
    width: 70%;
  }
  .doctor-img-box {
    width: 164px;
    margin-left: 0;
  }
  /* .row_margin {
        margin-bottom: 50px;
        padding-right: 119px;
    } */
  .circle_text {
    flex: 0 0 80%;
  }
  .solution_In_one_App_parrent {
    margin: 15px 46px;
  }
  .about_mytute {
    margin: 0 !important;
  }
  .center_small {
    padding-right: 18px !important;
  }
  .text-center {
    margin-left: 0 !important;
    padding: 0 10px;
  }
  p.mytute_p {
    max-width: 100%;
  }
  .card_cutm {
    margin-left: 44px !important;
  }
  .home_first_sec_left {
    width: 100% !important;
  }
  .home_laptopImg_Right {
    margin-top: 50px !important;
  }
  .daweeye_pharmacy p {
    padding: 0 20px;
  }
  .bell-icon-link {
    margin-top: 0px;
  }

  .doctor_dashboard_inbox {
    margin-left: 62px !important;
    width: 86% !important;
  }
  .doctor_dashboard_earnings {
    margin-left: 64px !important;
    width: 86% !important;
  }
  .doctor_dashboard_statistics {
    margin-left: 67px !important;
    width: 86% !important;
  }
  .week-box {
    margin-top: 2px;
  }
  .notification-menu .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
    top: 291px !important;
    left: 164px !important;
    width: 80% !important;
}
.schdulors.row {
    
    gap: 24px !important;
}
.appointmentsTables.custmTable.row {
    width: 91% !important;
    margin-left: 49px !important;
}
.appointmentsTables.custmTable.row {
    width: 91% !important;
    margin-left: 39px !important;
}
.chats-left-box, .chats-right-box {
    width: 91% !important;
    margin-left: 48px !important;
}
.MidLabBtn.text-center {
    margin-top: 0px !important;
}
.statistics_wrapper > .row > div:nth-child(1) {
    gap: 147px !important;
    justify-content: flex-start !important;
}
.appointmentsTables {
    width: 92% !important;
    margin-left: 11% !important;
}
.f-right-services {
    padding: 0 !important;
}
.pharmacy-card {
  margin-left: 114px !important;
  width: 70%;
}
}

@media (max-width: 480px) {
  .mb-10-px {
    margin-bottom: 15px !important;
    display: flex !important;
    align-items: flex-start !important;
  }
  .c-f-b-s {
    margin-right: 15px !important;
    margin-top: 7px !important;
    /* transform: scale(-1.5); */
  }
  .product_select {
    width: 87%;
    margin-left: 55px;
  }
  .cart-icon-container {
    margin-right: 25px !important;
  }
  .text-icon-x {
    justify-content: space-between;
  }
  .cart-icon-container {
    margin-right: 20px !important;
  }
  .pharmacy-card {
    margin-left: 58px;
  }
  .cart-icon-container {
    padding: 10px 10px;
    margin-right: -4px;
  }
  .appointmentsTables {
    width: 87%;
  }
  .footer-logo img {
    width: 80% !important;
    height: 84% !important;
  }
  .dashboard-topbar-rightside {
    margin-top: 35px !important;
  }
  .input-group {
    width: 93%;
    margin: 0 auto;
  }
  .MidLabBtn button {
    width: 205px !important;
    height: 48px;
  }
  .MidLabBtn .active {
    margin-left: 5px !important;
  }
  .apponitment_tableee {
    width: 343px !important;
  }
  /* Home page starts */
  h1 {
    font-size: 32px !important;
  }
  h2 {
    font-size: 22px;
  }
  h3 {
    font-size: 26px !important;
  }
  h5 {
    font-size: 16px !important;
  }
  .navbar {
    padding: 0px 0px;
  }
  .ScrollIcon {
    margin-top: -60px;
  }
  .home-right-first {
    transform: scale(0.7) !important;
  }
  .circle {
    left: 5px !important;
  }
  .Problem_Worth {
    height: 500px;
  }
  /* Home page ends */
  /* Login starts */
  .loginForm label {
    font-size: 18px;
  }
  .loginFormSection {
    padding-block: 60px;
    padding-inline: 18px;
  }
  .loginImg {
    margin-top: -90px;
  }
  /* Login ends */
  .setting_fileupload {
    width: 50%;
    height: 100%;
  }
  /* Dashboard css starts */
  .ProfileRightBox {
    margin-left: 87px;
    width: 79%;
  }
  #chart {
    margin-left: 30%;
  }
  .dashboard-topbar-rightside-box {
    margin: 30px 29px 30px 0;
  }
  /* Dashboard css ends */
  /* Setting page starts */
  /* .dashboard-topbar-rightside-box {
        flex-direction: column-reverse;
        justify-content: end;
        align-items: end;
        margin: 15px 0px;
    } */
  .dashboard h5 {
    margin-left: 23%;
  }
  .dashboadrd_setting {
    margin: 0 auto;
  }
  h2.text-white.p-3.ps-5 {
    font-size: 18px;
  }
  .UploadProfile p {
    margin-left: -22px;
  }
  .setting_fileupload {
    width: 75%;
  }
  .ps-5 {
    padding-left: unset !important;
  }
  /* Setting page ends */
  .custmTable {
    margin: 20px 60px;
  }
  .paymentCards .balanceCard,
  .withdrwaRow .balanceCard {
    margin: 10px 80px;
  }
  .doctor_dashboard_newyork_block,
  .doctor_dashboard_inbox,
  .doctor_dashboard_earnings,
  .doctor_dashboard_statistics {
    margin-left: 70px !important;
  }
  .ProfileRightBox {
    margin-left: 42px !important;
    width: 80%;
  }
  /* Chatscreen starts  */
  .chat-section .chats-right-box .chat-timeline .time {
    font-size: 10px !important;
  }
  .chats-right-box,
  .chat-section .chats-left-box {
    margin-left: 90px;
  }
  .chat-section .chats-left-box .chat-list .time-box span {
    font-size: 10px !important;
  }
  h6 {
    font-size: 12px;
  }
  .dashboard-topbar-rightside-box {
    margin-right: 30px;
  }
  /* Chatscreen ends  */
  tspan {
    font-size: 12px;
  }
  .ProfileRightBoxSeeAll h6 {
    font-size: 16px !important;
    margin: 0 auto !important;
  }
  .dashboadrd_setting {
    width: 84%;
  }
  #chart {
    margin-left: 17%;
  }
  .small-modal-x .modal-content {
    width: 100% !important;
  }
  .opt-outer-div {
    margin-left: 0px !important;
  }
  .chats-left-box,
  .chats-right-box {
    width: 88% !important;
    margin-left: 50px !important;
  }
  .dashboard h5 {
    margin-left: 14% !important;
  }
  .SelectCityMain h5 {
    margin-left: 32px !important;
  }
  .doctorsNearsCard {
    width: 88% !important;
    margin-left: 52px !important;
  }
  .px-290 {
    width: 125px !important;
  }
  .apponitment_tableee {
    width: 414px !important;
  }
  .px-290 {
    width: 182px !important;
  }
  .withdrwaRow .balanceCard h5 {
    margin-left: 0 !important;
  }
  .font24 {
    font-size: 16px !important;
  }
  .dashboard-visitDoctors-box,
  .dashboard-Pharmacy-box,
  .dashboard-covid19-box,
  .dashboard-rightSide {
    width: 85%;
    margin: 20px 63px;
  }
  .address_col {
    width: 60%;
  }
  .about_mytute {
    padding-right: 27px !important;
  }
  .circle_text {
    flex: 0 0 85%;
  }
  .storeImg {
    gap: 10px;
  }
  .doctor-img-box {
    text-align: end;
  }
  .dashboard-visitDoctors-box,
  .dashboard-Pharmacy-box,
  .dashboard-covid19-box,
  .dashboard-rightSide {
    /* width: 85%; */
    margin: 0px 63px;
  }
  .doctor_dashboard_inbox {
    margin-left: 58px !important;
  }
  .doctor_dashboard_earnings {
    margin-left: 57px !important;
  }
  .doctor_dashboard_statistics {
    margin-left: 59px !important;
  }
  .statistics_wrapper > .row > div:nth-child(1) {
    gap: 113px;
    justify-content: flex-start !important;
  }
  .apexcharts-canvas {
    right: 65px !important;
  }
  .notification-menu .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
    top: 291px !important;
    left: 150px !important;
    width: 80% !important;
}
.schdulors.row {
    gap: 7px !important;
}
.dashboadrd_setting {
    width: 90% !important;
    margin-left: 47px !important;
}
.product_select.col-md-3 {
  margin-left: 61px !important;
  width: 87%;
}
.pharmacy-card {
  margin-left: 82px !important;
  width: 80%;
}
}

@media (max-width: 420px) {
    .appointmentsTables {
        width: 92% !important;
        margin-left: 9% !important;
    }
    .appointment_searchBars {
        margin: 20px 30px !important;
        width: 94% !important;
    }
  .dashboard h5 {
    margin-left: 15% !important;
  }
  .product_select {
    width: 87%;
    margin-left: 46px;
  }
  .pharmacy_box_2 {
    width: 100% !important;
    margin: -5px -30px !important;
  }
  .pharmacy-card {
    margin-left: 53px;
    width: 89%;
  }
  .dashboard-topbar-rightside {
    margin-top: 0px !important;
  }
  .doctor-detail-tabs-card {
    width: 360px;
  }
  .doctor-detail-tabs-card {
    margin-left: 39px !important;
  }
  .doctorDetail-fees-card {
    margin-left: 39px !important;
  }
  .doctorDetail-experience-card {
    margin-left: 39px !important;
  }
  .MidLabBtn button {
    width: 177px !important;
    font-size: 15px !important;
    /* height: 48px; */
  }
  .text-center {
    margin-left: 0px !important;
  }
  .Problem_Worth {
    height: 600px;
  }
  .nav__cont {
    width: 40px;
  }
  ul li img {
    width: 20px;
    margin-left: -6px;
  }
  .doctor_dashboard_newyork_block {
    margin: 0 auto;
  }
  .doctor_dashboard_inbox {
    margin: 0 auto;
  }
  .doctor_dashboard_earnings {
    margin: 20px auto;
  }
  .doctor_dashboard_earnings,
  .doctor_dashboard_statistics {
    margin: 0 auto;
  }
  .ProfileRightBox {
    margin: 20px auto;
  }
  #chart {
    transform: scale(0.8);
    margin-left: 26%;
  }
  .paymentCards .balanceCard,
  .withdrwaRow .balanceCard {
    margin: 10px 72px;
    width: 296px;
  }
  .withdrwaRow .widthdrawl_btn,
  .paymentCards .payment_btn {
    font-size: 14px;
    padding: 10px 12px;
  }
  /* Dashboard starts */
  .doctor_dashboard_newyork_block,
  .doctor_dashboard_inbox,
  .doctor_dashboard_earnings,
  .doctor_dashboard_statistics {
    margin-left: 55px !important;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .doc-dashboad-2 {
    margin-left: 37px !important;
    width: 91% !important;
  }
  .doctor_dashboard_earnings,
  .doctor_dashboard_statistics {
    width: 91% !important;
    margin-left: 37px !important;
  }
  .ProfileRightBox {
    margin-left: 46px !important;
  }
  /* Dashboard ends */
  /* Chatscreen page starts */
  .chats-right-box,
  .chat-section .chats-left-box {
    margin-left: 44px;
    width: 94%;
  }
  #chart {
    transform: scale(0.7) !important;
    margin-left: 14%;
  }
  p {
    font-size: 14px !important;
  }
  .home_laptopImg {
    margin-top: 30px !important;
  }
  .chat-section .chats-right-box .chats-container .right-message .time,
  .chat-section .chats-right-box .chats-container .left-message .time {
    font-size: 10px !important;
  }
  .chat-section .chats-right-box .chat-footer .message-input::placeholder {
    font-size: 12px;
  }
  .chat-section .chats-right-box .chat-footer .message-input {
    padding: 2% 0px !important;
  }
  .chat-section .chats-right-box .chat-footer .send-btn {
    padding: 6px !important;
  }
  .chat-section .chats-right-box .chat-footer .send-btn .icon {
    width: 25px !important;
    height: 20px !important;
  }
  .dashboard-topbar-rightside-text .small {
    font-size: 12px !important;
  }
  /* Chatscreen page ends */
  .dashboadrd_setting {
    width: 100%;
  }
  .dashboard-topbar-img-box {
    width: 45px;
    height: 45px;
  }
  .cameraicon {
    width: 140px;
    height: 140px;
  }
  .input-group {
    width: 94%;
    margin: 0 auto;
  }
  .withdrwaRow .withdrw,
  .paymentCards .payment {
    margin-left: -20px !important;
  }
  .paymentCards .balanceCard,
  .withdrwaRow .balanceCard {
    margin: 10px 47px !important;
    width: 84% !important;
  }
  .dashboadrd_setting {
    width: 90% !important;
    margin-left: 40px !important;
  }
  .doctorsNearsCard {
    width: 86% !important;
    margin-left: 50px !important;
  }
  .MidLabBtn button {
    width: 177px !important;
    font-size: 15px !important;
  }
  .px-290 {
    width: 102px !important;
  }
  .dashboard-visitDoctors-box,
  .dashboard-Pharmacy-box,
  .dashboard-covid19-box,
  .dashboard-rightSide {
    width: 70%;
    margin: 20px 72px;
  }
  .icons_profile {
    width: 100% !important;
  }
  .px-290 {
    width: 152px !important;
  }
  .schdulors {
    margin-left: 33px !important;
    /* margin-bottom: 30px; */
  }
  .schdulors.row {
    gap: 32px !important;
  }
  .withdrwaRow .withdrw,
  .paymentCards .payment {
    margin-left: 0px !important;
  }
  .summary_block {
    margin-left: 44px !important;
  }
  .proceed_btn {
    margin-left: 11px !important;
  }
  .setting_fileupload {
    width: 67% !important;
  }
  .dashboard-visitDoctors-box,
  .dashboard-Pharmacy-box,
  .dashboard-covid19-box,
  .dashboard-rightSide {
    width: 85%;
    margin: 0px 50px;
  }
  .SelectCityMain button {
    font-size: 12px;
    padding-left: 4px;
  }
  .ProfileRightBox {
    margin-left: 23px !important;
    /* margin-bottom: 20px; */
    width: 97% !important;
  }
  .solution_In_one_App_parrent {
    margin: 15px 40px;
  }
  .notification-menu .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
    top: 291px !important;
    left: 129px !important;
    width: 80% !important;
}
.footer-ul-icon{
    margin-left: 5px;
}
.Schedule-x h5 {
    margin-left: 35px !important;
}
.chats-left-box, .chats-right-box {
    width: 92% !important;
    margin-left: 35px !important;
}
.lab-servicers h5 {
    margin-left: 33px !important;
    margin-top: 17px;
}
.appointmentsTables {
    width: 94% !important;
    margin-left: 11% !important;
}
.SelectCityMain h5 {
  margin-left: 31px !important;
}
.pharmacy-card {
  margin-left: 58px !important;
  width: 87%;
}
.product_select.col-md-3 {
  margin-left: 45px !important;
  width: 87%;
}
}
@media (max-width: 376px){
  .doctor-img-box {
    width: 128px !important;
    margin-left: 0;
  }
  .dashboard-rightSide {
    width: 88%;
    margin: 0px 34px;
}
.dashboard-visitDoctors-box, .dashboard-Pharmacy-box, .dashboard-covid19-box, .dashboard-rightSide{
  width: 92%;
    margin: 0px 25px;
}
.doctor-detail-tabs-card {
  width: 334px;
  margin-left: 27px !important;
}
.pharmacy-card {
  margin-left: 55px !important;
  width: 87%;
}
}

@media (max-width: 375px) {

  .pharmacy_box_2 {
    width: 112% !important;
    margin: -5px -32px !important;
  }
  .dashboard h5 {
    margin-left: 16% !important;
  }
  .product_select {
    width: 85%;
    margin-left: 46px;
  }
  .pharmacy-card {
    margin-left: 48px;
  }
  .category_row {
    margin-left: 58px;
  }
  .Problem_Worth {
    height: 650px;
  }
  /* .doctor-detail-tabs-card {
                      margin-left: -17px;
                      margin-top: -56px;
                  } */
  /* .text-center {
                      margin-top: -56px !important;
                  } */
  .doctor-detail-tabs-header button {
    font-size: 11px !important;
  }
  .doctor-detail-tabs-card {
    width: 323px;
  }
  .doctor-detail-tabs-card {
    margin-left: 34px !important;
  }
  /* .login-para{
    margin-top: 24px !important;
  } */
  
  .text-center {
    margin-top: -34px !important;
    margin-left: 32px !important;
  }
  /* .login-para2 {
    margin-top: 25px !important;
} */
  .MidLabBtn button {
    width: 136px !important;
    font-size: 12px !important;
  }
  .MidLabBtn .active {
    margin-left: -4px;
    margin-top: -50px;
    width: 158px;
    height: 44px;
  }
  .dashboard h5 {
    margin-left: 8% !important;
  }
  .MedicalHead p {
    margin-left: 39px;
  }
  .red_box {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .red_box_main {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
  }
  .Problem_Worth {
    height: 730px;
  }
  .doctor_dashboard_earnings,
  .doctor_dashboard_statistics {
    margin: 20px auto;
  }
  /* Setting page starts */
  h2.text-white.p-3.ps-5 {
    font-size: 15px !important;
  }
  .dashboard h5 {
    margin-left: 26%;
  }
  h2.text-white.p-3.ps-5 {
    font-size: 14px;
  }
  /* Setting page ends */
  .doctor_dashboard_newyork_block,
  .doctor_dashboard_inbox,
  .doctor_dashboard_earnings,
  .doctor_dashboard_statistics {
    margin-left: 60px !important;
  }
  .ProfileRightBox {
    margin-left: 48px !important;
  }
  .font20 {
    font-size: 10px !important;
  }
  h5 {
    font-size: 14px !important;
  }
  p {
    font-size: 12px !important;
  }
  .font26 {
    font-size: 14px !important;
  }
  #earnings_week,
  #statistics_month {
    font-size: 14px !important;
  }
  .inbox_card .card_img {
    width: 40px !important;
    height: 58px !important;
  }
  .modal-content {
    width: 100% !important;
  }
  .apppointmentDest_closeBtn > button,
  .areYouSureBtn > button {
    padding: 12px 38px !important;
  }
  .doctor_dashboard_newyork_block,
  .doctor_dashboard_inbox,
  .doctor_dashboard_earnings,
  .doctor_dashboard_statistics {
    margin-left: 51px !important;
  }
  .ProfileRightBox {
    margin-left: 42px !important;
  }
  #chart {
    transform: scale(0.6) !important;
    margin-left: 9%;
  }
  #react-time-range .component {
    padding: 36px !important;
  }
  .personalInfo-btns button {
    padding: 14px 30px;
  }
  .setting_fileupload {
    width: 50%;
    font-size: 12px;
    /* margin-left: 74px !important; */
  }
  .cameraicon {
    width: 120px;
    height: 120px;
  }
  .dashboadrd_setting input {
    padding: 8px;
    font-size: 12px;
  }
  .chats-left-box,
  .chats-right-box {
    width: 88% !important;
    margin-left: 40px !important;
  }
  .dashboadrd_setting {
    /* width: 90% !important; */
    margin-left: 37px !important;
  }
  .UploadProfile {
    margin-left: 0 !important;
    /* text-align: center !important; */
  }
  .UploadProfile p {
    margin-left: -6px !important;
    /* text-align: center !important; */
  }
  .SelectCityMain h5 {
    margin-left: 32px !important;
  }
  .px-290 {
    width: 130px !important;
  }
  .dashboard-visitDoctors-box,
  .dashboard-Pharmacy-box,
  .dashboard-covid19-box,
  .dashboard-rightSide {
    width: 80%;
    margin: 20px 52px;
  }
  .doctor-detail-imgBox {
    width: 84px !important;
    height: 83px !important;
  }
  .MedicalHead p {
    margin-left: 15px !important;
    line-height: 0.5;
  }
  .px-290 {
    width: 130px !important;
  }
  .dashboard h5 {
    margin-left: 11% !important;
  }
  .schedule-row {
    margin-left: 36px !important;
  }
  .dashboard-visitDoctors-box,
  .dashboard-Pharmacy-box,
  .dashboard-covid19-box,
  .dashboard-rightSide {
    width: 92%;
    margin: 0px 32px;
  }
  .address_col {
    width: 50%;
  }
  .doctor-img-box img {
     /* width: 60%;  */
  }
  .solution_bg {
    margin-left: -22px;
    padding: 0 10px;
  }
  .card_cutm {
    margin-left: 58px !important;
  }
  .row_margin {
    margin-left: -55px;
  }
  .how_can_we_help_col {
    margin-left: -54px;
  }
  .storeImages {
    width: 60% !important;
    height: 60% !important;
  }
  .get-started-now-btn {
    height: 45px;
    width: 170px;
    font-size: 14px;
    margin-left: 26px;
  }
  .navbar .container {
    margin-left: 0;
  }
  .about_secSecontion {
    margin-left: 20px;
  }
  td {
    font-size: 14px;
  }
  .ourTeam {
    font-size: 14px;
  }
  .row_margin h3 {
    margin-left: 54px !important;
  }
  .SelectCityBtnDiv {
    width: 47% !important;
    margin-right: 5px !important;
  }
  .doc-dashboad-2 {
    margin-left: 37px !important;
    width: 91% !important;
  }
  .doctor_dashboard_earnings,
  .doctor_dashboard_statistics {
    width: 91% !important;
    margin-left: 37px !important;
  }
  .ProfileRightBox {
    margin-left: 24px !important;
  }
  .copyrights {
    /* margin: 8px 09; */
    margin: 2px 0 !important;
  }
  .week-box {
    margin-top: 66px !important;
  }
  .footertxtCent3 {
    margin-bottom: 40px;
  }
  .notification-menu .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
    top: 291px !important;
    left: 111px !important;
    width: 80% !important;
}
.schdulors.row {
    gap: 10px !important;
}
.MidLabBtn.text-center {
    margin-top: 15px !important;
}
.statistics_wrapper > .row > div:nth-child(1) {
    gap: 91px !important;
    justify-content: flex-start !important;
}
}

@media (max-width: 320px) {
  .modal-content {
    padding: 5px !important;
  }
  .appointment_searchBars {
    margin: 20px 23px !important;
    width: 95% !important;
}
  h2 {
    font-size: 20px;
  }
  h3 {
    font-size: 22px !important;
  }
  .pharmacy-card {
    margin-left: 44px;
  }
  .category_row {
    margin-left: 62px;
  }
  .circle {
    left: -8px !important;
  }
  .setting_fileupload {
    width: 64%;
  }
  .doctor_dashboard_newyork_block,
  .doctor_dashboard_inbox,
  .doctor_dashboard_earnings,
  .doctor_dashboard_statistics {
    margin-left: 36px !important;
  }
  .inbox_card .card_img {
    width: 40px;
    height: 40px;
  }
  .setting_fileupload {
    width: 74%;
    margin-left: 10px !important;
    font-size: 12px;
  }
  .UploadProfile p {
    margin-left: -32px !important;
  }
  .ProfileRightBox {
    margin-left: 28px !important;
  }
  .doctor_dashboard_newyork_block,
  .doctor_dashboard_inbox,
  .doctor_dashboard_earnings,
  .doctor_dashboard_statistics {
    margin-left: 47px !important;
  }
  .ProfileRightBox {
    margin-left: 40px !important;
  }
  .schdulors {
    margin-left: 100px;
  }
  .dashboard h5 {
    margin-left: 44%;
  }
  #react-time-range .component .label {
    padding-right: unset !important;
  }
  .dashboard h5 {
    margin-left: 28%;
  }
  .paymentCards .balanceCard,
  .withdrwaRow .balanceCard {
    margin: 10px 43px !important;
    width: 84% !important;
  }
  .chats-right-box,
  .chat-section .chats-left-box {
    margin-left: 32px;
    width: 100%;
  }
  .dashboadrd_setting {
    width: 100%;
    margin-left: 34px;
  }
  .opt-outer-div {
    margin: 378px 0 !important;
    margin-left: -16px !important;
  }
  .UploadProfile p {
    margin-left: -6px !important;
  }
  .setting_fileupload {
    width: 74% !important;
    margin-left: 28px !important;
    font-size: 12px;
  }
  .opt-outer-div {
    margin: 450px 0 !important;
    margin-left: -16px !important;
  }
  .doctorsNearsCard {
    width: 84% !important;
    margin-left: 45px !important;
  }
  .doctor-detail-tabs-card {
    width: 272px !important;
  }
  .doctor-detail-tabs-card {
    margin-left: 32px !important;
  }
  .MidLabBtn button {
    width: 119px !important;
    font-size: 10px !important;
    margin-left: 42px;
}
  .text-center {
    margin-left: 40px !important;
  }
  .px-290 {
    width: 126px !important;
  }
  .apppointmentDest_closeBtn > button,
  .areYouSureBtn > button {
    padding: 10px 0px !important;
  }
  .schedule-row {
    margin-left: 34px !important;
  }
  .dashboard-visitDoctors-box,
  .dashboard-Pharmacy-box,
  .dashboard-covid19-box,
  .dashboard-rightSide {
    width: 85%;
    margin: 0px 40px;
  }
  .apppointmentDest_closeBtn > button,
  .areYouSureBtn > button {
    padding: 12px 25px !important;
  }
  .setting-x h5 {
    margin-left: 14% !important;
  }
  .text-icon-x h5 {
    margin-left: 14% !important;
  }
  .proceed_btn {
    margin-left: 12px !important;
  }
  .doctor-img-box {
    width: 143px;
    margin-left: 0;
  }
  .navbar .container {
    margin-left: 0;
  }
  .navbar-toggler {
    width: 20%;
  }
  .navbar-brand {
    margin: 0;
    width: 80%;
  }
  p.mytute_p {
    max-width: 94%;
  }
  .how_can_we_help_col {
    margin-left: -83px;
  }
  .circle {
    width: 40px;
    height: 40px;
  }
  .mainDiv_circle {
    padding-inline: 24px;
  }
  .daweeye_pharmacy p {
    padding: 0 0;
  }
  .SelectCityBtnDiv {
    width: 47% !important;
    margin-right: 5px !important;
  }
  .doc-dashboad-2 {
    margin-left: 33px !important;
    width: 91% !important;
  }
  .doctor_dashboard_earnings,
  .doctor_dashboard_statistics {
    width: 91% !important;
    margin-left: 33px !important;
  }
  .ProfileRightBox {
    margin-left: 22px !important;
  }
  .statistics_wrapper > .row > div:nth-child(1) {
    gap: 70px;
    justify-content: flex-start !important;
  }
  .notification-menu .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
    top: 291px !important;
    left: 96px !important;
    width: 82% !important;
}
.schdulors.row {
    grid-gap: 10px !important;
    gap: 36px !important;
}
.appointmentsTables.custmTable.row {
    width: 91% !important;
    margin-left: 32px !important;
}
.mywalletPara p.small {
    margin-left: 34px !important;
    padding: 0 13px !important;
}
.f-right-services button {
    padding: 5px 7px;
    font-size: 12px;
}
.dashboard-visitDoctors-box, .dashboard-Pharmacy-box, .dashboard-covid19-box, .dashboard-rightSide {
  width: 94%;
  margin: 0px 30px;
}
.heading-schedul-x h5 {
  margin-left: 51px !important;
}
.product_select.col-md-3 {
  margin-left: 38px !important;
  width: 88%;
}
.pharmacy-card {
  margin-left: 40px !important;
  width: 95%;
}
}

.footer-bg {
    /* height: 557px; */
    background-color: #ef464b !important;
    /* position: relative; */
}

.text-left.footer_main.container h6 {
    color: #ffffff;
    font-size: 30px;
}

.footertxtCent2 {
/* text-align: center; */
}

.text-left.footer_main.container-fluid h6 {
    font-size: 30px;
    color: #ffffff;
}

.text-left.footer_main.container-fluid .row {
    padding-top: 90px;
}

.footer_first_col.col-md-4 {
    margin-top: -35px;
}

.text-left.footer_main.container-fluid .max-width {
    display: flex;
}

.text-left.footer_main.container-fluid {
    background-color: #f9f9f9;
}

.text-left.footer_main.container-fluid {
    text-align: center;
}

.footer-logo {
    width: 226.85px;
    height: 65.85px;
}

.fw-600 {
    font-weight: 600;
}

.footer-ul-list-icon {
    width: 28px;
    height: 28px;
}

.footer-subscribe-btn {
    background-color: #0b30a9;
    width: 44px;
    height: 44px;
    border-radius: 50px;
    border: none;
    outline: none;
    position: absolute;
    right: 2%;
    top: 8px;
    bottom: 8px;
}

.copyrights {
    padding: 80px 0;
}

.footer_form_input::placeholder {
    background: #ffffff;
    color: #ef464b;
}


/* .text-md-center{
  text-align: center;
} */
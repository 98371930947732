.login-btn {
    border-radius: 50px;
    border: 1px solid #ffffff;
    color: #ffffff;
    /* background-color: transparent; */
    width: 140px;
    height: 60px;
    margin-right: 10px;
    font-weight: 500 !important;
    font-size: 20px;
    text-align: center;
    padding-top: 12px;
}

.navbar {
    box-shadow: none;
}

.sign-up-btn {
    border-radius: 50px;
    color: #ffffff;
    background-color: #ef464b;
    border: 1px solid #ef464b;
    width: 140px !important;
    height: 60px !important;
    font-weight: 500 !important;
    font-size: 20px;
    text-align: center;
    padding-top: 12px;
}

.web-color {
    color: #ef464b !important;
    font-size: 18px;
    font-weight: 600;
}

.textBlack {
    color: black !important;
    font-weight: 600;
    font-size: 18px;
}

.text-color {
    color: #0b30a9;
}

.text-gray {
    color: #7d7d7d;
}

.play-and-app-img {
    width: 200px;
    height: 67px;
}

.play-and-app-img img {
    box-shadow: 1px 1px 5px 1px #88a3fc;
    border-radius: 20px;
}

.font-19 {
    font-size: 19px;
}

.font-18 {
    font-size: 18px;
}

.font-13 {
    font-size: 13px;
}

.font-semi-bold {
    font-family: 500;
}

.height-26 {
    height: 26px;
}

.height-27 {
    height: 27px;
}

.height-13 {
    height: 13px;
}

.height-40 {
    height: 40px;
}

.link-black {
    color: black !important;
    font-weight: 500;
}

.header-logo {
    width: 226.85px;
    height: 65.61px;
}

.font-60 {
    font-size: 60px;
}

/* Input password */
.input-password{
    position: relative;
}
.input-password input{
    padding-right: 65px;
}
.input-password .password-icon{
    position: absolute;
    right:20px;
    top: 50%;
    bottom: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
}
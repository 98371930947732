.latest-update-col {
    display: flex;
    justify-content: center;
}

.bookDoctor_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.book-doc-container.row {
    margin-bottom: 100px !important;
}

@media (max-width:1920px) {}

@media (max-width:1600px) {}

@media (max-width:1440px) {
    .exploreByCategory::before {
        background-position-x: -27% !important;
    }
    .exploreByCategory {
        align-items: center !important;
        width: 96% !important;
    }
    .custmDoctorBox.row {
        padding: 10px 30px !important;
    }
    .consult_doctor h3 {
        font-size: 35px !important;
    }
    /* .exploreCategory-row>.col-lg-3{
        padding: 10px !important;
    } */
}

@media (max-width:1400px) {}

@media (max-width:1366px) {}

@media (max-width:1280px) {
    .border_boxes_parent>.row {
        gap: 20px;
        justify-content: center;
    }
}

@media (max-width:1024px) {
    .exploreByCategory::before {
        /* background-position-x: -8% !important; */
        background-image: none !important;
        content: none !important;
        /* background-color: #0b30a9;    */
    }
    .exploreByCategory {
        background-color: #0b30a9;
        height: 100% !important;
        width: 100% !important;
        align-items: center !important;
        padding-bottom: 0px !important;
        padding: 50px !important;
        margin: 20px 0px !important;
        /* width: 90%;
        height: 80% !important;  */
    }
    .exploreCategory {
        height: 81% !important;
        width: 90% !important;
        margin: auto !important;
    }
    .exploreCategory-row {
        row-gap: 20px !important;
    }
    .border_boxes_parent>.row {
        justify-content: center !important;
        gap: 20px;
    }
    .bookDoctor_text h3 {
        margin: 30px 0px !important;
    }
    .col {
        padding: 0 !important;
    }
    .storeImages {
        width: 166px !important;
        height: 56px !important;
    }
    .easySteps_Solution {
        margin: 50px 0px !important;
    }
    .innerBorder_box {
        height: 90% !important;
    }
    .exploreCategory h5 {
        font-size: 18px !important;
    }
    .book-doc-container.row {
        padding: 63px !important;
        margin-bottom: 0px !important;
    }
}

@media (width:768px) {
    /* .row>.col-md-6 {
        flex-shrink: 0;
        width: 100%;
        max-width: 100%;
    } */
    .row>.col-md-4 {
        flex-shrink: 0;
        width: 50%;
        /* max-width: 50%; */
    }
}

@media (max-width:768px) {
    .BannerRound {
        display: none;
    }
    .homeBgRightangle {
        max-width: 5%;
        right: 46%;
        top: -39px;
    }
    .home_first_sec_left {
        text-align: center;
    }
    .heading p {
        margin: 0 auto;
        max-width: 91%;
    }
    .home_first_sec_left {
        margin-top: 40px;
    }
    .storeImg {
        justify-content: center;
    }
    .home-right-first {
        transform: scale(0.6) !important;
    }
    .storeImg {
        padding-bottom: unset;
    }
    .solution_bg {
        margin-top: 0px;
    }
    .redBgImg {
        background-image: unset !important;
    }
    .about_mytute,
    .about_mytute p {
        text-align: center;
    }
    .circle {
        position: relative;
        left: 30px;
    }
    .dawaee_help_img>img {
        transform: translate(0.8);
    }
    .dawaee_help_img>img {
        transform: scale(0.8);
    }
    .people-says-about-us {
        background-image: unset;
        background-color: #0b30a9;
    }
    .quoteImg_main {
        display: flex !important;
        justify-content: center;
        align-items: center !important;
        margin: 20px 0px;
    }
    .people-says-about-us {
        height: 500px !important;
    }
    .book_doctor_headings {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 100%;
        margin: 30px 0px;
    }
    .book_doctor_headings p {
        text-align: center !important;
    }
    .book_dr_img {
        text-align: center;
    }
    .storeImg {
        justify-content: center;
        margin: 20px 0px;
    }
    .easySteps_Solution {
        margin: 0px !important;
    }
    /* .border_boxes1, .border_boxes3 { 
        width: 94%;
        height: 100%;
    } */
    /* .border_boxes2, .border_boxes4 { 
    .border_boxes1,
    .border_boxes3 {
        width: 94%;
        height: 100%;
    }
    .border_boxes2,
    .border_boxes4 {
        width: 94%;
        height: 100%;
    } */
    .home_laptopImg img {
        width: 100%;
    }
    .home-img-container {
        /* width: 100% !important; */
        margin: 0 auto !important;
    }
    .consult_doctor h3 {
        max-width: 100% !important;
        margin-top: 20px !important;
    }
    .storeImages {
        margin-right: 26px !important;
    }
    .doctorRedBg {
        margin-bottom: 40px !important;
    }
    .bookDoctor_Img {
        margin: 20px 0 !important;
    }
    .storeImages {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 5px 30px #0b30a91a;
        border-radius: 10px;
        opacity: 1;
        width: 166px !important;
        height: 56px;
        margin-right: 20px;
    }
    .center_small {
        margin-top: 30px !important;
    }
    .virus-right-first {
        right: 66% !important;
        width: 166px !important;
    }
    .marginTop {
        margin-top: 0px !important;
    }
    .home_first_sec_left.col-md-6 {
        text-align: center !important;
    }
    .consult_doctor {
        text-align: center !important;
    }
}

@media screen and (max-width: 767px) {
    .workingforBetterHealth .transmition_main .transmision_col {
        width: 70%;
        margin: 0 auto
    }
    .bookDoctor_Img {
        width: 100% !important;
        text-align: center !important;
    }
    .bookDoctor_Img img {
        width: 60%;
    }
    .bookDoctor_text h3 {
        padding: 0px 40px;
    }
    .virusCut {
        display: none;
    }
    .coronavirus_covid19 {
        width: 100% !important;
    }
    .center_small {
        padding-right: 0px !important;
    }
    .virus-right-first {
        display: none !important;
    }
    .home_laptopImg {
        margin-top: 0 !important;
    }
    .text-center {
        margin-top: 30px !important;
    }
    .card_Main {
        width: 100% !important;
    }
}

@media (max-width:420px) {
    .consult_doctor h3 {
        font-size: 22px !important;
    }
    .storeImages {
        margin-right: 26px !important;
        width: 89% !important;
        height: 75% !important;
    }
    .easySteps_Solution h3 {
        font-size: 20px !important;
        /* margin: 24px 0px !important; */
    }
    .exploreByCategory h3 {
        margin-bottom: 38px !important;
    }
    .bookDoctor_text h3 {
        padding: 0px !important;
        font-size: 20px !important;
    }
}

@media (max-width:320px) {
    .storeImages {
        margin-right: 10px !important;
        width: 90% !important;
        height: 70% !important;
    }
    .consult_doctor h3 {
        font-size: 18px !important;
    }
    .bookDoctor_text h3 {
        padding: 0px !important;
        font-size: 18px !important;
    }
}
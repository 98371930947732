.people-says-about-us {
    background-image: url('../images/peoples-says-about-us.png');
    background-size: 100% 100%;
    height: 100vh;
    background-repeat: no-repeat;
    /* max-width: 100%; */
    /* width: 100%; */
    /* float: right; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.whatPeopleSays {
    max-width: 1280px;
}

.people-says-about-us-text-box {
    /* max-width: 80%; */
    color: #FFFFFF;
    opacity: 1;
    text-align: left;
}


/* .people-says-about-us-title-box{
    max-width: 60%;
    margin-top: 35%;
} */

.avatarDiv {
    justify-content: center;
}

.quoteImg {
    position: relative;
    bottom: 15%;
    left: 5%;
}
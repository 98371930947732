.support_mainContainer {
  width: 100%;
}

.support_Head {
  height: 569px;
  background-color: #0b30a9;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.support_Head h1 {
  color: white;
  font-weight: 900;
}

.support_Head p {
  margin-top: 10px;
  color: white;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
}

.support_body {
  /* width: 80%; */
  margin: 30px;
}

.support_introduction {
  color: #0b30a9;
}

.mg-top {
  margin-top: 130px;
}

.support_Heading {
  margin-top: 30px;
}

.support_Div h1 {
  color: #0b30a9;
  font-weight: 900;
}

.support_Div h2 {
  color: #0b30a9 !important;
  font-weight: 900 !important;
  font-size: 36px !important;
}
.support_Div h3 {
  color: #0b30a9;
  font-weight: 900;
  margin-top: 130px;
}

.support_Div h4 {
  color: #0b30a9;
  font-weight: 900;
}

.support_Div h5 {
  color: #0b30a9;
  font-weight: 900;
}

.support_Div h6 {
  color: #0b30a9;
  font-weight: 900;
}

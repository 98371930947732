.font26 {
    font-size: 26px;
}

.font20 {
    font-size: 20px;
}

.doctor_dashboard_newyork_block {
    padding: 30px;
    background-color: #EFF3FF;
    border-radius: 20px;
    margin-bottom: 40px;
}

.doctor_dashboard_newyork_block h6 {
    font-size: 20px;
}

.doctor_dashboard_inbox {
    /* border: solid; */
    padding: 30px;
    box-shadow: 0px 10px 50px #0000001c;
    border-radius: 20px;
}

.inbox_wrapper {
    overflow-y: scroll;
    height: 410px;
    padding-right: 10px;
}

.inbox_wrapper::-webkit-scrollbar {
    width: 5px;
    height: 0px;
}

.inbox_wrapper::-webkit-scrollbar-thumb {
    background: #0000004f;
    border-radius: 20px;
}

.inbox_wrapper h5 {
    font-size: 26px;
    margin-bottom: 20px;
}

.inbox_card {
    border: 1px solid #dcdcdc;
    border-radius: 20px;
    margin-bottom: 20px;
}

.inbox_card p {
    margin: 0;
}

.inbox_card .inbox_card_wrapper {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
}

.card_content {
    width: 100%;
}

.msg-para {
    white-space: nowrap;
    width: 259px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bold_custom {
    font-weight: 600;
}

.inbox_card .card_content .name_date {
    /* border: 1px solid blue; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.inbox_card .card_img {
    width: 80px;
    height: 80px;
    /* border: 1px solid orange; */
    margin-right: 10px;
    border-radius: 20px;
}

.card_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
    border-radius: 20px;
}


/* .new_functionality_block{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 50px;
    background-color: #EF464B;
    border-radius: 20px;
    margin-top: 30px;
}
.new_functionality_block h5{
    text-align: center;
    margin: 0;
    color: white;
} */

.doctor_dashboard_earnings {
    margin-bottom: 30px;
}

.doctor_dashboard_earnings,
.doctor_dashboard_statistics {
    padding: 30px;
    box-shadow: 0px 10px 50px #0000001c;
    border-radius: 20px;
    width: 100%;
    height: 313px;
}

.doctor_dashboard_earnings .heading_week,
.doctor_dashboard_statistics .heading_month {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#earnings_week,
#statistics_month {
    border: none;
    background: transparent;
    color: #6A6A6A;
    font-size: 18px;
    font-weight: 500;
}


/* Statistics css starts */

.statistics_wrapper .heading_month {
    margin-bottom: 20px;
}

.statistics_main_wrapper {
    display: flex;
    margin-bottom: 20px;
}

.statistics_main_wrapper .dot_icon {
    background: #0B30A9;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 10px;
    border-radius: 50%
}

.statistics_main_wrapper .dot_icon_red {
    background: #EF464B;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 10px;
    border-radius: 50%
}

.statistics_main_wrapper .gendertxt p {
    margin-bottom: 0;
    color: #6A6A6A;
}

.statistics_main_wrapper .gendertxt h5 {
    font-weight: 700;
    color: #000000;
}

.apexcharts-canvas {
    position: relative;
    user-select: none;
    right: 58px;
}

.apexcharts-radialbar-slice-0 {
    stroke: #0B30A9;
}

.apexcharts-radialbar-slice-1 {
    stroke: #EF464B;
}


/* Statistics css end */
:root {
  --blue-color: #0b30a9;
  --pink-color: #7d7d7d;
  --black-color: #000000;
  --orange-color: #ef464b;
  --white-color: #fff;
}

.easySteps_Solution h3 {
  color: var(--blue-color);
  font-weight: 800;
  text-align: center;
}

.doctorRedBg {
  position: relative;
  margin-bottom: 230px;
}

.marginTop {
  margin-top: 230px;
}

.redBgImg {
  background-image: url(../images/DoctorRedBg.png);
  background-size: 40%;
  background-repeat: no-repeat;
  background-position: top;
  position: absolute;
  width: 100%;
  height: 900px;
  top: -3%;
  right: 30%;
  z-index: -1;
}

.redBgImg_Right {
  background-image: url(../images/red_bg.png);
  background-size: 40%;
  background-repeat: no-repeat;
  background-position: top;
  position: absolute;
  width: 100%;
  height: 900px;
  top: -3%;
  left: 30%;
  z-index: -1;
}

.home_laptopImg {
  margin-top: 100px;
  display: flex;
  justify-content: center;
}

.home_laptopImg_Right {
  margin-top: 100px;
  display: flex;
  justify-content: center;
}

.home_laptopImg_Right img {
  width: 65%;
}

.home_laptopImg img {
  width: 65%;
}

.easySteps_Solution {
  margin: 400px 0px 50px 0px;
}

.custmContainer {
  max-width: 1600px;
}

.consult_doctor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
}

.consult_doctor h3 {
  color: var(--blue-color);
  font-weight: 700;
  margin-bottom: 35px;
  max-width: 80%;
}

/* Doctor box component starts */

.doctorBox {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}

.doctorBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  border-radius: 20px;
  background: #fff;
  padding: 50px;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
}

.doctorBox h5 {
  color: var(--black-color);
  font-weight: 700;
  margin-top: 30px;
}

.custmDoctorBox.row {
  row-gap: 20px;
}

/* Doctor box component ends */

.exploreByCategory {
  position: relative;
  height: 850px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 90px;
}

.exploreByCategory::before {
  content: "";
  background-image: url("/src/assets/images/peoples-says-about-us.png");
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  top: 62px;
  width: 100%;
  height: 800px;
  transform: scaleX(1.5);
  left: 36px;
  z-index: -1;
}

.exploreCategory {
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 65px;
  border-radius: 50px;
  background-color: var(--white-color);
}

.exploreCategory h5 {
  color: var(--black-color);
  font-weight: 700;
  margin-top: 15px;
}

.exploreByCategory h3 {
  text-align: center;
  font-weight: 600;
  color: var(--white-color);
  margin-bottom: 20px;
}

.bookDoctor_text h3 {
  color: var(--blue-color);
  font-weight: 600;
}

.virus-right-first {
  position: absolute;
  /* margin-top: -210px; */
  right: 54%;
  top: 55%;
  z-index: -1;
  /* width: 60%; */
}

.mobileandvirus {
  position: relative;
}

.coronavirus_covid19 {
  position: relative;
  width: 67%;
}

.virusCut {
  /* margin-right: 0px !important;
  padding-right: 0px !important; */
  position: absolute;
  right: 0%;
}

.transmition_main {
  /* background-color: red; */
  text-align: center;
}

.transmision_col {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  /* border: 1px solid black; */
}

.transmision_index {
  color: #ef464b;
  font-weight: bolder;
  font-size: 60px;
  margin-bottom: 20px;
}

.transmision_head {
  font-size: 21px;
  color: black;
  font-weight: bold;
  margin-bottom: 20px;
}

.latestUpdate_main {
}

.whatsholudIVaccine {
  max-width: 60%;
  margin: auto;
}

.shouldVaccineImage {
  display: flex;
  justify-content: flex-end !important;
}

/* Card Style */
.card_Main {
  width: 95%;
  height: 464px;
  border-radius: 20px;
  padding: 20px;
  cursor: pointer;

  box-shadow: 0px 0px 18px -7px rgba(0, 0, 0, 0.63);
  -webkit-box-shadow: 0px 0px 18px -7px rgba(0, 0, 0, 0.63);
  -moz-box-shadow: 0px 0px 18px -7px rgba(0, 0, 0, 0.63);
}

.card_Image {
  width: 100%;
  height: 50%;
  object-fit: cover;
  border-radius: 20px;
}

.card_Image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
  /* background-color: red; */
}

.card_body {
  padding: 10px;
}

.card_title p {
  color: #0b30a9;
  font-size: 20px;
  font-weight: 900 !important;
  /* font-family: ; */
}

.card_description {
  opacity: 1 !important;
  color: black !important;
  word-break: break-all;
}

.card_readMore {
  color: #ef464b;
  font-weight: 900 !important;
  cursor: pointer;
}

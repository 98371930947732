.whatsholudIVaccine {
    max-width: 80%;
}

.whatsholudIVaccine h3 {
    font-size: 52px;
}

@media screen and (max-width: 1440px) {
    .center_small {
        padding-right: 80px;
    }
    .daweeye_pharmacy h3 {
        font-size: 52px;
    }
    .whatsholudIVaccine h3 {
        font-size: 48px;
    }
    .let_daweyee.center_small {
        padding-right: 0px;
    }
}

@media screen and (max-width: 1400px) {
    .Problem_Worth {
        padding-left: 150px;
        padding-right: 100px;
    }
}

@media screen and (max-width: 1280px) {
    .problemWorth_Para {
        margin-top: 100px;
    }
    .doctorRedBg {
        margin-top: 215px;
    }
    .daweeye_pharmacy h3 {
        font-size: 44px;
    }
    .whatsholudIVaccine h3 {
        font-size: 42px;
    }
    .location_compatibility {
        padding-left: 250px;
        padding-right: 100px;
    }
    .abtTable_main {
        overflow-x: initial;
    }
}

@media screen and (max-width: 1024px) {
    .home_banner_b:before {
        width: 48%;
        height: 600px;
    }
    .twomobileimg {
        padding: 40px;
    }
    .doctorRedBg {
        margin-top: -50px !important;
    }
    .aboutVistDoctor_sect {
        margin-top: -120px;
    }
    .text-left.footer_main.container-fluid h6 {
        font-size: 25px;
    }
    .daweeye_pharmacy h3 {
        font-size: 40px;
    }
    .home_laptopImg img {
        width: 70%;
    }
    .redBgImg {
        background-size: 42%;
    }
    .whatsholudIVaccine h3 {
        font-size: 36px;
    }
    .whatsholudIVaccine {
        max-width: 100%;
        margin-left: 50px
    }
    .latestUpdate_main .card_description p {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .latestUpdate_main .card_title p {
        font-size: 18px;
    }
    .storeImg {
        padding-bottom: 0px;
        display: flex !important;
        justify-content: center !important;
        width: 100% !important;
    }
    .bookDoctor_text {
        text-align: center !important;
    }
    .squareBox {
        width: 100%;
    }
    .Problem_Worth {
        padding-left: 220px;
        padding-right: 100px;
    }
    .mission_analysis-parent {
        padding: 0px 30px;
    }
    .uniueSelling_proposition {
        padding: 0px 30px;
    }
    .listItmesss {
        margin-top: 120px;
        padding: 0px 30px;
    }
}

@media screen and (max-width: 768px) {
    .home_first_sec_left,
    .twomobileimg {
        width: 100%;
    }
    .home_first_sec_left .heading h3 {
        text-align: center;
    }
    .home_first_sec_left .heading p {
        max-width: 90%;
    }
    .twomobileimg img {
        width: 70%;
    }
    .twomobileimg {
        text-align: center;
        padding-top: 0px;
    }
    .daweyee_pharm_img,
    .daweyee_pharm_content {
        width: 100%;
    }
    .daweyee_pharm_img .home_laptopImg img {
        width: 50%;
    }
    .daweyee_pharm_content {
        order: 1
    }
    .daweyee_pharm_img {
        order: 2;
    }
    .redBgImg_Right {
        background-size: 60%;
        top: 10%;
        left: 30%;
    }
    .home_laptopImg_Right {
        margin-top: 250px !important;
    }
    .doctorRedBg {
        margin-top: 10px !important;
    }
    .workingforBetterHealth h6 {
        font-size: 20px;
    }
    .latestUpdate_main .col-md-4 {
        width: 50%;
        margin-bottom: 20px;
    }
    .latestUpdate_main .card_title p {
        font-size: 22px !important;
        text-align: center;
    }
    .let_daweyee,
    .let_daweyee_img {
        width: 100%;
    }
    .let_daweyee {
        padding: 0 50px !important;
    }
    .let_daweyee_img img {
        width: 50%;
    }
    .twomobileimg {
        padding-bottom: 0px;
    }
    .home-right-first {
        transform: unset;
        margin-top: -70px;
    }
    .book_doctor_headings {
        width: 65%;
    }
    .book_dr_img img {
        width: 70%;
    }
    .home_banner_b.mb-5 {
        margin-bottom: 0 !important;
    }
    .man-with-mask {
        order: 2;
    }
    .virusCut {
        display: none;
    }
    .coronavirus_covid19 {
        width: 90%;
    }
    .exploreCategory-row .col-lg-3 {
        margin: 0 auto;
        width: 60%;
    }
    .book-doc-container.row {
        justify-content: center;
    }
    .home_bg_picture.home-bg.row {
        justify-content: center;
    }
    .border_boxes {
        width: 70% !important;
    }
    .Problem_Worth {
        height: auto;
        padding: 50px 18px;
        margin: 30px 0;
    }
    .Problem_Worth::before {
        content: none;
    }
    .abtBusiness_Stratgies {
        padding: 0px 30px;
    }
    .businessToCustomer_inner {
        padding: 0px 30px;
    }
    .businessToCutomers_main .mobileImg_bg img {
        width: 75% !important;
    }
    .businessToCutomers_main .mobileImg_bg {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 767px) {
    .workingforBetterHealth .transmition_main .transmision_col {
        width: 70%;
        margin: 0 auto
    }
    .book_dr_img img {
        width: 60%;
    }
    .book_doctor_headings {
        padding: 0px 0px;
    }
    .man-with-mask {
        order: 2;
    }
    .redBgImg_Right {
        display: none;
    }
    .virusCut {
        display: none;
    }
    .coronavirus_covid19 {
        width: 90%;
    }
    .coronavirus-col {
        margin-top: 0px !important;
    }
    .covid_mobile img {
        margin-top: -40px !important;
    }
    .exploreCategory-row .col-lg-3 {
        margin: 0 auto;
        width: 60%;
    }
    .home_first_sec_left {
        width: 75%;
    }
    h5.text-center.mt-5.login-para {
        margin-top: 32px !important;
    }
    .login-para2 {
    margin-top: 25px !important;
}
}

@media screen and (max-width: 540px) {
    .redBgImg_Right {
        background-size: 70%;
        top: 60%;
    }
    .let_daweyee_img img {
        width: 59%;
    }
    .latestUpdate_main .col-md-4 {
        width: 70%;
        margin: 0 auto;
        margin-bottom: 20px;
    }
    .book_dr_img img {
        width: 75%;
    }
    .book_doctor_headings h3 {
        text-align: center;
    }
    .daweyee_pharm_content {
        padding: 0px 0px;
    }
    .home_first_sec_left .heading p {
        max-width: 100%;
    }
    .circle {
        left: 15px;
    }
    .home_laptopImg_Right {
        margin-top: 60px !important;
    }
    .home_laptopImg_Right img {
        width: 80% !important;
    }
}

@media screen and (max-width: 480px) {
    .daweyee_pharm_content {
        padding: 0px 0px;
    }
    .let_daweyee {
        padding: 0 15px !important;
    }
    .coronavirus-col {
        margin-top: 0px !important;
    }
    #solution {
        margin-top: -40px;
    }
    .exploreCategory-row .col-lg-3 {
        width: 75%;
    }
    .bookDoctor_Img img {
        width: 75%;
    }
    .mission_analysis-parent {
        padding: 0px 0px;
    }
}

@media screen and (max-width: 425px) {
    .home_first_sec_left .heading {
        padding-right: 0px;
    }
    .twomobileimg img {
        width: 100%;
    }
    .let_daweyee_img img {
        width: 75%;
    }
    .redBgImg_Right {
        background-size: 90%;
    }
    .latestUpdate_main .col-md-4 {
        width: 80%;
    }
    .daweyee_pharm_img .home_laptopImg img {
        width: 70%;
    }
    .bookDoctor_text h3 {
        padding: 0px;
    }
    .aboutpage_sec1 {
        width: 85%;
    }
    .red_box_main {
        flex-direction: column;
        align-items: center;
    }
    .red_box {
        width: 230px;
        height: 75px;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 375px) {
    .redBgImg_Right {
        top: 66%;
    }
    .home_first_sec_left .heading p {
        margin-bottom: 0px !important;
    }
    .transmision_col {
        padding: 30px 20px;
    }
    .latest_update_heading {
        margin: 0 20px;
    }
    .book_doctor_headings {
        width: 85%;
    }
    .exploreCategory-row .col-lg-3 {
        width: 100%;
    }
    .bookDoctor_Img img {
        width: 100%;
    }
    .text-center {
        margin-top: -24px !important;
        margin-left: 0px !important;
    }
    h5.text-center.mt-5.login-para {
        margin-top: 32px !important;
    }
    .login-para2 {
    margin-top: 25px !important;
}
}

@media screen and (max-width: 320px) {
    .latestUpdate_main .col-md-4 {
        width: 100%;
    }
    .workingforBetterHealth .transmition_main .transmision_col {
        width: 95%;
    }
    .about_mytute {
        margin: 0 20px !important;
    }
    .twomobileimg img {
        width: 100%;
    }
    .book_doctor_headings {
        width: 90%;
    }
    .covid_mobile img {
        margin-top: -40px !important;
    }
    .book-doc-container.row {
        padding: 30px !important;
    }
}
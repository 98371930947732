.bell-icon-link {
    border: none !important;
    position: relative !important;
}

.bell-icon-link .icon {
    color: #707070;
    cursor: pointer;
}

.notification-menu p {
    margin-bottom: 0;
}

.notification-menu ul.MuiList-root.MuiList-padding.MuiMenu-list {
    padding-top: 20px;
    padding-bottom: 20px;
}

.notification-menu svg {
    margin-right: 5px;
    color: #8e8e8e;
}

.notification-menu p {
    color: #8e8e8e;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    letter-spacing: -0.34px !important;
}

.notification-menu li.MuiMenuItem-root.MuiMenuItem-gutters:hover svg {
    color: #000000 !important;
}

.notification-menu li.MuiMenuItem-root.MuiMenuItem-gutters:hover p {
    color: #000000 !important;
}

.notification-menu .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
    border-radius: 20px 0px 0px 20px;
    position: absolute !important;
    /* width: 30%; */
    height: 40vh;
    width: 30%;
    border: 1px solid #1337AC;
    /* top:120px !important; */
    /* left: 54% !important; */
    transform: translate(-58%, 49px) !important;
}

.notification-menu .notification-box {
    border-bottom: 1px solid #707070;
    width: 100%;
    padding: 5px 0px;
}


/* noti-container */

.noti-item {
    padding: 0 10px;
    border-bottom: 1px solid #eee;
    margin-bottom: 10px;
    cursor: pointer;
}

.noti-date {
    color: #8e8e8e !important;
    font-size: 12px !important;
    text-align: end;
}

.no-noti {
    width: 100%;
    height: 35vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: large;
}
:root {
    --black-color: #000000;
    --pink-color: #7d7d7d;
    --orange-color: #ef464b;
    --blue-color: #0b30a9;
    --white-color: #fff;
}

.aboutus_heading {
    color: var(--dark_Blue);
}

.aboutus_helpDive1 {
    display: flex;
    margin-top: 100px;
}

.aboutus_helpDive2 {
    width: 66px;
    height: 66px;
    background-color: var(--dark_pink);
    border-radius: 50%;
    margin: auto;
}

.aboutus_helpDive3 {
    width: 1px;
    height: 10vh;
    background-color: var(--dark_pink);
    border: 1px solid var(--dark_pink);
    margin: auto;
}

.aboutus_helpDive5 {
    width: 66px;
    height: 66px;
    border-radius: 50%;
    margin: auto;
    border: 1px solid var(--dark_pink);
    background-color: var(--dark_pink);
}

.aboutus_helpDive4 {
    width: 1px;
    height: 5.7vh;
    margin: auto;
    border: 1px solid var(--dark_pink);
}


/* Asif Css */

.about_secSecontion {
    margin-top: 100px;
}

.circle {
    width: 70px;
    height: 70px;
    background: #ef464b;
    border-radius: 50%;
}

.line {
    position: relative;
}

.line::before {
    content: " ";
    position: absolute;
    width: 2px;
    height: 150px;
    background-color: var(--orange-color);
    top: 56px;
    left: 34px;
    z-index: -1;
}

.circle_vision {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
}

.circle_text {
    flex: 0 0 85%;
}

.circle_text>h2 {
    color: var(--black-color);
    text-align: left;
}

.circle_text>p {
    color: var(--pink-color);
    text-align: left;
}

.border_boxes_parent {
    display: flex;
    justify-content: center;
    align-items: center;
}

.exploreByCategory-section {
    overflow: hidden;
}

.border_boxes1,
.border_boxes3 {
    text-align: center;
    width: 280px;
    height: 300px;
    border: 6px dotted #ef464b;
    border-radius: 20px;
    color: #000000;
}

.border_boxes2,
.border_boxes4 {
    text-align: center;
    width: 280px;
    height: 300px;
    border: 6px dotted #0b30a9;
    border-radius: 20px;
    margin: 0 20px 0 20px;
}

.border_boxes_parent h6 {
    color: #000000;
    font-weight: 700;
}

.border_boxes_parent p {
    color: var(--pink-color);
}

.innerBorder_box {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-radius: 20px;
    /* width: 260px; */
    padding: 12px;
    /* height: 260px; */
    margin: 12px auto;
    box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
}

.custmBg {
    position: relative;
}

.Problem_Worth {
    position: relative;
    height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 90px;
}

.Problem_Worth h1,
.Problem_Worth p {
    color: #ffff;
    text-align: center;
}

.Problem_Worth::before {
    content: " ";
    background: url(/src/assets/images/peoples-says-about-us.png);
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 62px;
    width: 100%;
    height: 800px;
    transform: scaleX(1.5);
    left: 36px;
    z-index: -1;
}

.aboutTable_thead {
    background-color: var(--orange-color);
}

.aboutTable_thead th {
    font-size: 30px;
    font-weight: bold;
    color: #fff;
    text-align: center;
}

.aboutTable_thead th:nth-child(1) {
    font-size: 30px;
    font-weight: bold;
    color: #fff;
    text-align: center;
}

.aboutTable_thead td {
    color: var(--pink-color);
    font-size: 18px;
}

.tableBodyAbout {
    background-color: #fff;
}

.abtTable_main {
    position: relative;
}

.main_tablee {
    position: absolute;
    bottom: -290px;
}

.problemWorth_Para {
    margin-top: 325px;
    position: relative;
    text-align: center;
}


/* .problemWorth_Para::before {
    content: "";
    background-image: url(/src/assets/images/home-bg-rightangle.png);
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    width: 7%;
    height: 95px;
    right: 112%;
}

.problemWorth_Para::after {
    content: "";
    background-image: url(/src/assets/images/solutionAngle.png);
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    width: 7%;
    height: 95px;
    left: 106%;
    top: -131%;
}
 */

.problemWorth_Para p {
    color: var(--pink-color);
}

.abtTable_main {
    position: relative;
}


/* .abtTable_main::before {
    content: "";
    background-image: url(../images/bg2.png);
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height: 416px;
    right: 32%;
    top: -73px;
    transform: scaleX(1);
}
 */

.mission_analysis-parent {
    overflow: hidden;
}

.abtTable_main::before {
    content: "";
    background-image: url(../images/bg2.png);
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    width: 26%;
    height: 458px;
    left: 0;
    top: 44px;
    transform: scale(0.9) translateX(-130%);
}

.abtTable_main::after {
    content: "";
    background-image: url(../images/bg1.png);
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    width: 26%;
    height: 416px;
    right: 0;
    top: 65px;
    transform: scale(1) translateX(111%);
}

.about_mytute p {
    color: var(--pink-color);
}

.productAndServices {
    display: flex;
    width: 600px;
}

.productRight {
    width: 550px;
}

.productRight h5 {
    color: #ef464b;
    font-weight: bold;
    margin: 15px 0;
}

.productRight p {
    text-align: left;
}

.about-summary-sec {
    position: relative;
    margin-top: 100px;
}

.about-summary-sec>img {
    position: absolute;
    width: 50%;
}

.about-summary-sec p {
    width: 500px;
    text-align: left;
}

.mission_analysis {
    /* margin-top: 400px; */
}


/* AboutVisitDoctor Css starts */

.squareBox {
    background: var(--blue-color);
    width: 200px;
    height: 100px;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    flex-wrap: wrap;
    padding: 10px 20px;
}


/* AboutVisitDoctor Css ends */

.uniueSelling_proposition {
    margin: 100px 0;
}

.unique_selling h5 {
    color: var(--orange-color);
}

.unique_selling p {
    color: var(--pink-color);
    text-align: left;
}

.unique_selling .usp {
    margin: 30px 0;
}

.unique_selling ul>li {
    font-size: 18px;
    color: var(--pink-color);
    padding: 10px 0;
}

.unique_selling ul li::marker {
    background-color: var(--orange-color);
    border: var(--orange-color);
    font-size: 30px;
    border: 1px solid var(--orange-color);
}

.about_business>h3 {
    color: var(--blue-color);
}

.about_business>h6 {
    color: var(--orange-color);
}

.about_business>p {
    color: var(--pink-color);
    text-align: left;
}

.abtBusiness_Stratgies {
    position: relative;
    overflow: hidden;
}

.bg_red {
    background-image: url(../images/red_bg.png);
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    width: 68%;
    height: 100%;
    right: -35%;
    transform: scaleX(1.5);
}

.pc_img {
    position: relative;
}

.pc_img img {
    width: 38%;
    position: absolute;
    top: 194px;
}

.red_box_main {
    display: flex;
    justify-content: space-around;
}

.red_box {
    width: 280px;
    height: 130px;
    background: #ef464b;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 7px;
    padding: 0 15px 0 15px;
}

.businessToCutomers_main {
    margin-top: 50px;
}

.businessToCutomers_main .businessToCustomer_inner p {
    color: var(--pink-color);
    text-align: left;
}

.businessToCutomers_main .businessToCustomer_inner h5 {
    color: #ef464b;
}

.businessToCustomer_inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    height: 100%;
}

.businessToCustomer_inner img {
    margin-left: -66px;
}

.mobileBg img {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    width: 80%;
}

.mobileImg_text h5 {
    color: #ef464b;
    font-weight: bold;
}

.mobileImg_text p {
    color: var(--pink-color);
    text-align: left;
}

.mobileImg_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    text-align: left;
    align-items: left;
}

.Problem_Worth h5 {
    color: var(--white-color);
    font-weight: 700;
}

.Problem_Worth p {
    color: var(--white-color);
    text-align: left;
}


/* AboutBox Components starts */

.about_boxsRow {
    justify-content: center;
}

.aboutboxx {
    width: 200px;
    height: 170px;
    background: var(--orange-color);
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.aboutboxx2 {
    width: 200px;
    height: 170px;
    background: #fff;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: 0px 0px 1px #000000;
}

.aboutVistDoctor_sect .row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.aboutboxx p.big {
    color: #fff;
}

.aboutboxx2 p.big {
    color: #000;
}

.about_boxs {
    margin-top: 42px;
}


/* AboutBox Components ends */

.listItmesss {
    margin-top: 80px;
}

.ourTeam {
    text-align: center;
    margin: 50px 0;
}

.ourTeam h3 {
    color: var(--blue-color);
    font-weight: 700;
}